import { Button, IconButton, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorToast, successToast } from '../shared/notification';
import axiosInstance from "../shared/api"

const TransplantDate = ({ device_id }) => {
    const [transplantInput, setTransplantInput] = useState({
        ref: "B1",
        block: "Block 1",
        plant: "",
        date: ""
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getTransplantData()
    }, [])

    function getTransplantData() {
        setLoading(true)
        axiosInstance.get("https://api.cloudatik.com/remote/V1.0/transplant/date/"+device_id).then(res => {
            setLoading(false)
            setTransplantInput(res.data[0])
        }).catch( err => {
            setLoading(false)
            console.log("err", err)
        })
    }

    function handleSubmitTransplant() {
        setLoading(true)
        console.log("handleSubmitTransplant", JSON.stringify([transplantInput]))
        const payload = JSON.stringify([transplantInput])
        axiosInstance.post("https://api.cloudatik.com/remote/V1.0/transplant/date/"+device_id+"/", {data: payload}).then(res => {
            setLoading(false)
            // setTransplantInput(res.data[0])
            getTransplantData()
            successToast("Successfully saved")
        }).catch( err => {
            setLoading(false)
            console.log("err", err)
            errorToast("Server Error")
        })
    }

    function handleTransplantInputChange(e, type) {
        console.log("handleTransplantInputChange", e.target.value, type)
        setTransplantInput(prev => ({
            ...prev,
            [type]: e.target.value
        }))
    }

    function clearDate() {
        console.log("clearDate")
        setTransplantInput(prev => ({
            ...prev,
            date: "",
            plant: ""
        }))
    }

    return (
        <>
            <div className="modal" style={{
                display: (loading ? 'block' : 'none'),
                position: 'fixed',
                zIndex: 1000,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                background: "rgba( 255, 255, 255, .8 ) url('https://i.stack.imgur.com/FhHRx.gif') 50% 50% no-repeat",
            }}>
            </div>
            
            <div style={{
                textAlign: 'center', maxWidth: '750px',
                margin: 'auto',
            }}>
                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                    BLOCK
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                    PLANT
                    </div>
                    <div style={{width: "70%"}}>
                    TRANSPLANT DATE
                    </div>
                </div>

                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput.block} disabled/>
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput.plant} onChange={(e)=>{handleTransplantInputChange(e, "plant")}}/>
                    </div>
                    <div style={{width: "50%"}}>
                        <input type="date" style={{width: "100%"}} value={transplantInput.date} onChange={(e)=>{handleTransplantInputChange(e, "date")}}/>
                        <Button variant="contained" color="primary" style={{marginTop:"0.4rem"}} onClick={()=>{clearDate()}}>clear</Button>
                    </div>
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={()=>{handleSubmitTransplant()}}>SAVE</Button>
                </div>
            </div>
        </>
    )
}

export default TransplantDate