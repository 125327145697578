import React, { useEffect } from "react";
import * as steelseries from "steelseries";

function LcdDisplay({ index, label, value, isMsg,width = 200 }) {
    let lcd;
    useEffect(() => {
        if(isMsg){
            lcd = new steelseries.DisplaySingle(
                document.getElementById(`lcd_status_${index}`),
                {
                    width: width,
                    height: 50,
                    headerString: "header",
                    valuesNumeric: false,
                    unitStringVisible: true,
                    headerStringVisible: true,
                    autoScroll: true,
                    value: value,
                }
            );
        }else{
            lcd = new steelseries.DisplaySingle(
                document.getElementById(`lcd_status_${index}`),
                {
                    width: width,
                    height: 50,
                    headerString: label,
                    valuesNumeric: true,
                    unitStringVisible: true,
                    headerStringVisible: true,
                    value: parseFloat(value),
                }
            );
        }
    }, []);

    return (
        <>
            <canvas id={`lcd_status_${index}`}></canvas>
        </>
    );
}

export default LcdDisplay;
