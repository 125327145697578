import React, { useEffect, useState } from "react";
import BarChart from "../../Components/Chart/BarChart";
import LineChart from "../../Components/Chart/LineChart";
import MultiLineChart from "../../Components/Chart/MultiLineChart";

function NodeMonitoring({
							lineChart,
							barChart,
							multilineChart,
							newData,
							minDate,
							newSinglePropData,
							newMultiPropData,
							newLcdData,
							lcdMessage
}) {
	multilineChart = multilineChart === undefined ? [] : multilineChart;
	lineChart = lineChart === undefined ? [] : lineChart;
	barChart = barChart === undefined ? [] : barChart;

	const [mDate, setMDate] = useState(minDate);
	const [nData, setNData] = useState(newData);

	useEffect(() => {
		setMDate(minDate);
	}, [minDate]);

	useEffect(() => {
		setNData(newData);
	}, [newData]);

	return (
		<>
			<div className="row">
				{multilineChart.map((multi_line_chart, index) => (
					<div className="col-sm-12 col-md-6">
						<MultiLineChart
							index={index}
							chart_title={multi_line_chart["chart_title"]}
							plot_limit={multi_line_chart["plot_limit"]}
							limit_low={multi_line_chart["limit_low"]}
							limit_low_parameter={multi_line_chart["limit_low_parameter"]}
							limit_high={multi_line_chart["limit_high"]}
							limit_high_parameter={multi_line_chart["limit_high_parameter"]}
							limit_low_head={multi_line_chart["limit_low_head"]}
							limit_high_head={multi_line_chart["limit_high_head"]}
							data={multi_line_chart["data"]}
							label={multi_line_chart["label"]}
							device_id={multi_line_chart["device_id"]}
							serial={multi_line_chart["serial"]}
							unit={multi_line_chart["unit"]}
							parameter={multi_line_chart["parameter"]}
							sensor_list={multi_line_chart["sensor_list"]}
							newData={nData}
							minDate={mDate}
							newMultiPropData={newMultiPropData}
							legend_title={multi_line_chart['legend_title']}
						/>
						<br />
					</div>
				))}
				{lineChart.map((line_chart, index) => (
					<div className="col-sm-12 col-md-6">
						<LineChart
							index={index}
							plot_limit={line_chart["plot_limit"]}
							limit_low={line_chart["limit_low"]}
							limit_high={line_chart["limit_high"]}
							data={line_chart["data"]}
							label={line_chart["label"]}
							device_id={line_chart["device_id"]}
							parameter={line_chart["parameter"]}
							unit={line_chart["unit"]}
							newData={nData}
							minDate={mDate}
							limit_low_parameter={line_chart["limit_low_parameter"]}
							limit_high_parameter={line_chart["limit_high_parameter"]}
							limit_low_head={line_chart["limit_low_head"]}
							limit_high_head={line_chart["limit_high_head"]}
							sensor_set={line_chart["sensor_set"]}
							newSinglePropData={newSinglePropData}
						/>
						<br />
					</div>
				))}
				{barChart.map((bar_chart, index) => (
					<div className="col-sm-12 col-md-6">
						<BarChart
							index={index}
							data={bar_chart["data"]}
							label={bar_chart["label"]}
							device_id={bar_chart["device_id"]}
							parameter={bar_chart["parameter"]}
							unit={bar_chart["unit"]}
							newData={nData}
							minDate={mDate}
						/>
						<br />
					</div>
				))}
			</div>
		</>
	);
}

export default NodeMonitoring;
