import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import AlertDialog from './AlertDialog';
import BlockSetting from './BlockSetting';
import StatusCard from './StatusCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    fontSize: '1rem',
    backgroundColor: blue[50],
    '&:disabled': {
      color: blue[50],
      backgroundColor: blue[500]
    }
  },
  disabledButton: {},
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

export default function FertigationBlockSetting({
  mqttClient,
  blockSettings,
  readBlockSettings,
  writeBlockSettings,
  isWriteSettingsInitiated,
  isReadSettingsInitiated,
  writeSettingsResult
}) {

  const [block1Settings, setBlock1Settings] = useState(null);
  const [block2Settings, setBlock2Settings] = useState(null);
  const [block3Settings, setBlock3Settings] = useState(null);
  const [block4Settings, setBlock4Settings] = useState(null);
  const [block5Settings, setBlock5Settings] = useState(null);

  const [isConnected, setIsConnected] = useState(false);

  const [currentBlockId, setCurrentBlockId] = useState(1);
  const [targetBlockId, setTargetBlockId] = useState(null);

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (mqttClient) {
      setIsConnected(true);

      const payload = {
        messageId: `rdb${currentBlockId}`,
        devices: `block${currentBlockId}`,
        mode: 1
      };
      readBlockSettings(payload);
    } else {
      setIsConnected(false);
    }
  }, [mqttClient, currentBlockId]);

  useEffect(() => {
    if (blockSettings) {
      console.log("*** blockSettings", blockSettings);
      const deviceData = blockSettings.deviceData;

      const blocks = [
        { id: "1", setterFunction: setBlock1Settings },
        { id: "2", setterFunction: setBlock2Settings },
        { id: "3", setterFunction: setBlock3Settings },
        { id: "4", setterFunction: setBlock4Settings },
        { id: "5", setterFunction: setBlock5Settings }
      ];
      for (const param in deviceData) {
        blocks.forEach(block => {
          if (param.includes(block.id)) {
            block.setterFunction(prevSettings => {
              const paramWithoutId = param.replace(block.id, "");
              return {
                ...prevSettings, [paramWithoutId]: deviceData[param]
              };
            });
          }
        });
      }
    }

  }, [blockSettings]);

  const handleSelectBlock = (target) => {
    setTargetBlockId(target);
    setIsAlertDialogOpen(true);
  };

  let displayedBlock = null;

  switch (currentBlockId) {
    case 1:
      displayedBlock = <BlockSetting
        id={1}
        blockSettings={block1Settings}
        readBlockSettings={readBlockSettings}
        writeBlockSettings={writeBlockSettings}
        isWriteSettingsInitiated={isWriteSettingsInitiated}
        isReadSettingsInitiated={isReadSettingsInitiated}
        writeSettingsResult={writeSettingsResult}
        handleSelectBlock={handleSelectBlock}
      />;
      break;
    case 2:
      displayedBlock = <BlockSetting
        id={2}
        blockSettings={block2Settings}
        readBlockSettings={readBlockSettings}
        writeBlockSettings={writeBlockSettings}
        isWriteSettingsInitiated={isWriteSettingsInitiated}
        isReadSettingsInitiated={isReadSettingsInitiated}
        writeSettingsResult={writeSettingsResult}
        handleSelectBlock={handleSelectBlock}
      />;
      break;
    case 3:
      displayedBlock = <BlockSetting
        id={3}
        blockSettings={block3Settings}
        readBlockSettings={readBlockSettings}
        writeBlockSettings={writeBlockSettings}
        isWriteSettingsInitiated={isWriteSettingsInitiated}
        isReadSettingsInitiated={isReadSettingsInitiated}
        writeSettingsResult={writeSettingsResult}
        handleSelectBlock={handleSelectBlock}
      />;
      break;
    case 4:
      displayedBlock = <BlockSetting
        id={4}
        blockSettings={block4Settings}
        readBlockSettings={readBlockSettings}
        writeBlockSettings={writeBlockSettings}
        isWriteSettingsInitiated={isWriteSettingsInitiated}
        isReadSettingsInitiated={isReadSettingsInitiated}
        writeSettingsResult={writeSettingsResult}
        handleSelectBlock={handleSelectBlock}
      />;
      break;
    case 5:
      displayedBlock = <BlockSetting
        id={5}
        blockSettings={block5Settings}
        readBlockSettings={readBlockSettings}
        writeBlockSettings={writeBlockSettings}
        isWriteSettingsInitiated={isWriteSettingsInitiated}
        isReadSettingsInitiated={isReadSettingsInitiated}
        writeSettingsResult={writeSettingsResult}
        handleSelectBlock={handleSelectBlock}
      />;
      break;
  }


  const handleCloseAlertDialog = ({ isConfirm }) => {
    if (isConfirm) {
      setCurrentBlockId(targetBlockId);
    }
    setIsAlertDialogOpen(false);
  };


  return (
    <section className={classes.root}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} lg={4}>
              <StatusCard isConnected={isConnected} />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container spacing={3} justifyContent='center'>
          {[1, 2, 3, 4, 5].map(id =>
            <Grid item xs={12} lg={2} classes={{ root: classes.gridItem }}>
              <Button
                size='large'
                fullWidth
                classes={{ root: classes.button, disabled: classes.disabledButton }}
                disabled={currentBlockId === id ? true : false}
                onClick={() => switchBlockTo(id)}
              >
                Block {id}
              </Button>
            </Grid>
          )}
        </Grid> */}
        <Grid item xs={12} lg={4}>
          {displayedBlock}
        </Grid>
        <AlertDialog
          isOpen={isAlertDialogOpen}
          handleClose={handleCloseAlertDialog}
          currentControlPanel={`block ${currentBlockId}`}
        />
      </Grid>
    </section>
  );
}
