import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import grey from '@material-ui/core/colors/grey';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 8,
    margin: theme.spacing(1),
  },
  button: {
    margin: '0 12px'
  },
  heading: {
    fontSize: '1.2rem'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 2
  },
  label: {
    marginBottom: 0
  },
  displayedTime: {
    textAlign: 'center'
  }
}));

export default function TimeSetting({
  id,
  sc_h,
  sc_m,
  io_sc,
  handleInputChange
}) {
  const classes = useStyles();

  const schInputProps = {
    step: 1,
    min: 0,
    max: 23
  };

  const scmInputProps = {
    step: 1,
    min: 0,
    max: 59
  };

  // session id to name mapping
  const SESSION = {
    a: '1',
    b: '2',
    c: '3',
    d: '4',
    e: '5',
    f: '6'
  };

  const handleSwitch = (event) => {
    event.target.value = event.target.checked ? 1 : 0;
    handleInputChange(event, `io_sc_${id}`);
  };

  const zeroPadTime = (value) => {
    return String(value).padStart(2, '0');
  };

  const displayedTime = `${zeroPadTime(sc_h)}:${zeroPadTime(sc_m)}`;

  return (
    <Box
      border={`1px solid ${grey[300]}`}
      borderRadius={4}
      padding={1}
      marginBottom={2}
    >
      <Box display='flex' alignItems='center' justifyContent='center'>
        <ScheduleIcon classes={{ root: classes.icon }} />
        <h4 className={classes.heading}>SESSION {SESSION[id]}</h4>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <FormControlLabel
          control={
            <Switch
              checked={io_sc === 1 ? true : false}
              onChange={handleSwitch}
              name="io_sc_switch"
              color="primary"
            />
          }
          label={io_sc === 1 ? 'ON' : 'OFF'}
        />
      </Box>
      <Collapse in={io_sc === 1}>
        <h2 className={classes.displayedTime}>{displayedTime}</h2>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
            <FormLabel component="label">HOUR</FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              size='small'
              inputProps={schInputProps}
              value={sc_h}
              onChange={(event) => handleInputChange(event, `sc_h_${id}`)}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
            <FormLabel component="label">MINUTE</FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              size='small'
              inputProps={scmInputProps}
              value={sc_m}
              onChange={(event) => handleInputChange(event, `sc_m_${id}`)}
            />
          </FormControl>
        </Box>
      </Collapse>
    </Box >
  );
}
