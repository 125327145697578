import React, { useState, useEffect } from "react";
import noUiSlider from "nouislider";

function Threshold({
    alarm_enabled,
    plot_limit,
    alarm_min,
    slider_min,
    alarm_max,
    slider_max,
    index,
    parameter,
    updateValue,
    label,
    limit_high,
    slide_mix,
    slide_max,
    multi,
    device_id,
    sensor_set
}) {
    const [alarmState, setAlarmState] = useState(alarm_enabled);
    const [plotState, setPlotState] = useState(plot_limit == 1 ? true : false);

    const [sliderMinState, setSliderMinState] = useState(
        alarm_min == null ? parseFloat(slider_min) : parseFloat(alarm_min)
    );
    const [sliderMaxState, setSliderMaxState] = useState(
        alarm_max == null ? parseFloat(slider_max) : parseFloat(alarm_max)
    );

    function plotChange() {
        setPlotState(!plotState);

        let d = {};
        d["limit_low"] = 0;
        d["limit_high"] = document
            .getElementById("visual_threshold" + index)
            .noUiSlider.get();
        d["parameter"] = parameter;
        d["enabled"] =
            document.getElementById("alarmSwitch" + index).checked == true ? 1 : 0;
        d["category"] = "threshold";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = document.getElementById("inputMin" + index).value;
        d["alarm_max"] = document.getElementById("inputMax" + index).value;
        d["plot_limit"] =
            document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);
    }

    function alarmChange() {
        setAlarmState(!alarmState);

        let d = {};
        d["limit_low"] = 0;
        d["limit_high"] = document
            .getElementById("visual_threshold" + index)
            .noUiSlider.get();
        d["parameter"] = parameter;
        d["enabled"] =
            document.getElementById("alarmSwitch" + index).checked == true ? 1 : 0;
        d["category"] = "slider";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = document.getElementById("inputMin" + index).value;
        d["alarm_max"] = document.getElementById("inputMax" + index).value;
        d["plot_limit"] =
            document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);
    }

    function handleChangeLow(e) {
        setSliderMinState(
            e.target.value == "" ? parseFloat(slide_mix) : parseFloat(e.target.value)
        );

        let input_from_min =
            e.target.value == "" || isNaN(e.target.value) ? 1 : e.target.value;

        document.getElementById("visual_threshold" + index).noUiSlider.updateOptions({
            range: {
                min: parseFloat(input_from_min),
                max: parseFloat(sliderMaxState),
            },
        });

        let d = {};
        d["limit_low"] = 0;
        d["limit_high"] = document
            .getElementById("visual_threshold" + index)
            .noUiSlider.get();
        d["parameter"] = parameter;
        d["enabled"] =
            document.getElementById("alarmSwitch" + index).checked == true ? 1 : 0;
        d["category"] = "slider";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = input_from_min;
        d["alarm_max"] = document.getElementById("inputMax" + index).value;
        d["plot_limit"] =
            document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);
    }

    function handleChangeHigh(e) {
        setSliderMaxState(
            e.target.value == "" ? parseFloat(slide_max) : parseFloat(e.target.value)
        );

        let input_from_max =
            e.target.value == "" || isNaN(e.target.value) ? 1 : e.target.value;

        document.getElementById("visual_threshold" + index).noUiSlider.updateOptions({
            range: {
                min: parseFloat(sliderMinState),
                max: parseFloat(input_from_max),
            },
        });

        let d = {};
        d["limit_low"] = 0;
        d["limit_high"] = document
            .getElementById("visual_threshold" + index)
            .noUiSlider.get();
        d["parameter"] = parameter;
        d["enabled"] =
            document.getElementById("alarmSwitch" + index).checked == true ? 1 : 0;
        d["category"] = "slider";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = document.getElementById("inputMin" + index).value;
        d["alarm_max"] = input_from_max;
        d["plot_limit"] =
            document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);
    }

    useEffect(() => {
        var handlesSlider = document.getElementById("visual_threshold" + index);
        noUiSlider.create(handlesSlider, {
            start: [parseFloat(limit_high)],
            range: {
                min: [parseFloat(alarm_min)],
                max: [parseFloat(alarm_max)],
            },
            tooltips: true,
            connect: [true, false],
            behaviour: "tap",
            pips: {
                mode: "steps",
                stepped: true,
                density: 4,
            },
        });

        document
            .getElementById("visual_threshold" + index)
            .noUiSlider.on("change", function (values, handle) {
                let d = {};
                d["limit_low"] = 0;
                d["limit_high"] = values[0];
                d["parameter"] = parameter;
                d["enabled"] =
                    document.getElementById("alarmSwitch" + index).checked == true
                        ? 1
                        : 0;
                d["category"] = "slider";
                d["device_id"] = device_id;
                d["username"] = localStorage.getItem("cloudatik");
                d["alarm_min"] = document.getElementById("inputMin" + index).value;
                d["alarm_max"] = document.getElementById("inputMax" + index).value;
                d["plot_limit"] =
                    document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
                d["label"] = label;
                d["sensor_set"] = sensor_set;
                updateValue(d, multi);
            });
    }, []);

    return (
        <div>
            <form>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">{label}</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"alarmSwitch" + index}
                                        onChange={alarmChange}
                                        checked={alarmState}
                                    />
                                    <label
                                        className="custom-control-label"
                                        for={"alarmSwitch" + index}
                                    >
                                        Alarm
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"plotSwitch" + index}
                                        onChange={plotChange}
                                        checked={plotState}
                                    />
                                    <label
                                        className="custom-control-label"
                                        for={"plotSwitch" + index}
                                    >
                                        Plot Limit
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <br />
                                <div id={"visual_threshold" + index}></div>
                                <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-md-6">
                                <br />
                                <input
                                    placeholder="MIN"
                                    id={"inputMin" + index}
                                    onChange={handleChangeLow}
                                    value={sliderMinState}
                                />
                                <br />
                            </div>
                            <div className=" col-md-6">
                                <br />
                                <div style={{ textAlign: "right" }}>
                                    <input
                                        placeholder="MAX"
                                        id={"inputMax" + index}
                                        onChange={handleChangeHigh}
                                        value={sliderMaxState}
                                    />
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Threshold;
