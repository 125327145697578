import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from 'react';
import AlertSnackbar from './AlertSnackbar';
import DaySetting from './DaySetting';
import StatusCard from './StatusCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    marginBottom: 16,
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: '0 12px'
  }
}));

export default function DaySettings({
  mqttClient,
  daySettings,
  readDaySettings,
  writeDaySettings,
  isWriteSettingsInitiated,
  isReadSettingsInitiated,
  writeSettingsResult
}) {

  const classes = useStyles();

  const mediumTypes = {
    water: {
      name: 'WATER',
      prefix: 'w'
    },
    fertilizer: {
      name: 'FERTILIZER',
      prefix: 'f'
    }
  };

  const [isConnected, setIsConnected] = useState(false);
  const [displayedMedium, setDisplayedMedium] = useState(mediumTypes.water);
  const [displayAllMedia, setDisplayAllMedia] = useState(false);

  useEffect(() => {
    if (mqttClient) {
      setIsConnected(true);
      const payload = {
        messageId: "rdds",
        devices: "day",
        mode: 1
      };
      readDaySettings(payload);
    } else {
      setIsConnected(false);
    }
  }, [mqttClient]);

  const [inputSettings, setInputSettings] = useState({
    w_mon: '',
    w_tue: '',
    w_wed: '',
    w_thu: '',
    w_fri: '',
    w_sat: '',
    w_sun: '',
    f_mon: '',
    f_tue: '',
    f_wed: '',
    f_thu: '',
    f_fri: '',
    f_sat: '',
    f_sun: '',
  });

  useEffect(() => {
    if (daySettings) {
      const deviceData = daySettings.deviceData;

      setInputSettings({
        w_mon: deviceData.w_mon,
        w_tue: deviceData.w_tue,
        w_wed: deviceData.w_wed,
        w_thu: deviceData.w_thu,
        w_fri: deviceData.w_fri,
        w_sat: deviceData.w_sat,
        w_sun: deviceData.w_sun,
        f_mon: deviceData.f_mon,
        f_tue: deviceData.f_tue,
        f_wed: deviceData.f_wed,
        f_thu: deviceData.f_thu,
        f_fri: deviceData.f_fri,
        f_sat: deviceData.f_sat,
        f_sun: deviceData.f_sun,
      });
    }
  }, [daySettings]);

  const handleClickSendButton = () => {

    const payload = {
      messageId: "wrds",
      devices: [{
        deviceId: "day",
        deviceData: {
          w_mon: inputSettings.w_mon,
          w_tue: inputSettings.w_tue,
          w_wed: inputSettings.w_wed,
          w_thu: inputSettings.w_thu,
          w_fri: inputSettings.w_fri,
          w_sat: inputSettings.w_sat,
          w_sun: inputSettings.w_sun,
          f_mon: inputSettings.f_mon,
          f_tue: inputSettings.f_tue,
          f_wed: inputSettings.f_wed,
          f_thu: inputSettings.f_thu,
          f_fri: inputSettings.f_fri,
          f_sat: inputSettings.f_sat,
          f_sun: inputSettings.f_sun,
        }
      }]
    };
    writeDaySettings(payload);
  };

  const handleClickRefreshButton = () => {
    const payload = {
      messageId: "rdds",
      devices: "day",
      mode: 1
    };
    readDaySettings(payload);
  };

  const handleInputChange = (event, param) => {
    let value = parseFloat(event.target.value);

    setInputSettings(settings => {
      return {
        ...settings,
        [param]: value
      };
    });
  };

  const handleClickArrow = () => {
    if (displayedMedium.name === 'WATER') {
      setDisplayedMedium(mediumTypes.fertilizer);
    } else {
      setDisplayedMedium(mediumTypes.water);
    }
  };

  return (
    <section className={classes.root}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} lg={4}>
              <StatusCard isConnected={isConnected} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
            <CardContent>
              {!displayAllMedia &&
                <Box display='flex' justifyContent='space-between'>
                  <IconButton onClick={handleClickArrow} disabled={displayAllMedia}>
                    <KeyboardArrowLeftIcon fontSize="large" />
                  </IconButton>
                  <IconButton onClick={handleClickArrow} disabled={displayAllMedia}>
                    <KeyboardArrowRightIcon fontSize="large" />
                  </IconButton>
                </Box>
              }
              <form noValidate autoComplete="off">
                <Collapse in={displayAllMedia}>
                  <DaySetting
                    medium={mediumTypes.water.name}
                    prefix={mediumTypes.water.prefix}
                    daySettings={inputSettings}
                    handleInputChange={handleInputChange}
                  />
                  <DaySetting
                    medium={mediumTypes.fertilizer.name}
                    prefix={mediumTypes.fertilizer.prefix}
                    daySettings={inputSettings}
                    handleInputChange={handleInputChange}
                  />
                </Collapse>
                <Collapse in={!displayAllMedia}>
                  <DaySetting
                    medium={displayedMedium.name}
                    prefix={displayedMedium.prefix}
                    daySettings={inputSettings}
                    handleInputChange={handleInputChange}
                  />
                </Collapse>
                <Box display='flex' justifyContent='center' alignItems='top' marginBottom='16px'>
                  <Button
                    variant="contained"
                    onClick={() => setDisplayAllMedia(prev => !prev)}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                  >
                    {displayAllMedia ?
                      "Show Less"
                      :
                      "Show All"
                    }
                  </Button>
                </Box>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickSendButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.button }}
                    startIcon={<SendIcon />}
                  >
                    SEND
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickRefreshButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.button }}
                    startIcon={<RefreshIcon />}
                  >
                    REFRESH
                  </Button>
                </Box>
              </form>
              <AlertSnackbar writeSettingsResult={writeSettingsResult} />
            </CardContent>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
          </Card >
        </Grid>
      </Grid>
    </section >

  );
}
