import React, { useEffect } from "react";
import * as steelseries from "steelseries";
import { Card, Icon, Image } from 'semantic-ui-react'

function CardLcd({ index, label, value, isMsg,width = 200 }) {

    function getvalueMsg() {
        if (label == 'Run/Stop') {
            if (value=='0.0') return 'RUN'
            else return 'STOP'
        } else {
            if (value=='1.0') return 'ON'
            else return 'OFF'
        }
    }
    
    return (
        <>
            <Card color='red'>
                <Card.Content>
                    <Card.Header>{label}</Card.Header>
                    <Card.Description>
                        <h2>{getvalueMsg()}</h2>
                    </Card.Description>
                </Card.Content>
            </Card>
            <br/>
        </>
    );
}

export default CardLcd;
