import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import category from '../../services/node-category/category';
import { successToast } from '../../shared/notification';

const EditCategoryModal = ({
    modalEditCat,
    setModalEditCat,
    categoryName,
    setLoading
}) => {
	const [newCatName, setNewCatName] = useState("");

    function submitCategoryName(e) {
		e.preventDefault();
		setLoading(true);
		console.log(newCatName)

        let dataToSubmit = {}

        category.getInstance().getCategory().then((result) => {
            result.map(r => {
                if (categoryName == r.category) {
                    dataToSubmit = {
                        id: r.id,
                        category: newCatName,
                    }
                    category.getInstance().updateCategory(dataToSubmit).then((result) => {
                        successToast(result["message"])
                        setLoading(false)
                        setTimeout(()=> {
                            window.location.reload()
                        }, 2000)
                    })
                    return null
                }
            })
        })
    }
    return (
			<Modal isOpen={modalEditCat}  className={"sm"} size="lg">
            <form onSubmit={(e)=>submitCategoryName(e)}>
                <ModalHeader onClick={()=>{setModalEditCat(false)}}>Edit {categoryName} Category Name</ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col">
                                <div className="form-group">
                                    <label for="txt_category">Old Category Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="txt_category"
                                        value={oldCatName}
                                        onChange={(e) => setOldCatName(e.target.value)}
                                        required
                                    />
                                    <small id="txt_category" className="form-text text-muted">
                                        Proper Category.
                                    </small>
                                </div>
                            </div> */}
                            <div className="col">
                                <div className="form-group">
                                    <label for="txt_new_category">New {categoryName} Category Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="txt_new_category"
                                        value={newCatName}
                                        onChange={(e) => setNewCatName(e.target.value)}
                                        required
                                    />
                                    <small id="txt_category" className="form-text text-muted">
                                        Proper Category.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-info">
                        Update Category
                    </button>
                    <Button color="secondary" onClick={()=>{setModalEditCat(false)}}>
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
        
    )
}

export default EditCategoryModal