import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as steelseries from "steelseries";
import { utcToMtyConvertionDate } from "../../shared/DateTimeConvertion";
import LcdDisplay from "../LCD";
const moment = require("moment-timezone");

function BarChart({
	index,
	data,
	label,
	device_id,
	newData,
	parameter,
	unit,
	minDate,
}) {
	let chart;
	let led;

	const [onChangeTitle, setOnChangeTitle] = useState(false);
	const [newTitle, setNewTitle] = useState("");
	const [latestData, setLatestData] = useState(0);
	const [chartB, setChartB] = useState("");

	useEffect(() => {
		am4core.addLicense("ch-custom-attribution");
		chart = am4core.create("chartdiv_bar_" + index, am4charts.XYChart);

		// Config
		chart.properties.paddingLeft = 0;

		let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.min = new Date(minDate).getTime();
		dateAxis.renderer.minGridDistance = 30;
		dateAxis.baseInterval = {
			count: 1,
			timeUnit: "minute",
		};

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

		let series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = "value";
		series.dataFields.dateX = "datetime";
		series.name = "Visits";
		series.columns.template.tooltipText = "{dateX}: [bold]{valueY}[/]";
		series.columns.template.fillOpacity = 0.8;
		let columnTemplate = series.columns.template;
		columnTemplate.strokeWidth = 2;
		columnTemplate.strokeOpacity = 1;

		chart.data = data;

		setChartB(chart);

		setLatestData(data[data.length - 1]["value"]);
		setNewTitle(label);
	}, [minDate]);

	useEffect(() => {
		try {
			chartB.addData(
				{
					datetime: utcToMtyConvertionDate(newData[0]["datetime"]),
					value: newData[0][parameter],
				},
				1
			);
		} catch (error) {
			console.log(error);
		}
	}, [newData]);

	useEffect(() => {
		try {
			setLatestData(newData[0][parameter]);
		} catch { }
	}, [newData]);

	function onUpdateTitle(e) {
		e.preventDefault();
	}

	return (
		<div className="card">
			<div className="card-body" style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
				<div>
					{!onChangeTitle && (
						<div onClick={() => setOnChangeTitle(true)}>
							<h3>{newTitle}</h3>
						</div>
					)}
					{onChangeTitle && (
						<form onSubmit={onUpdateTitle}>
							<div className="form-group">
								<label>New Title</label>
								<input
									type="text"
									className="form-control"
									id={"txt_" + label}
									placeholder="Enter New Title"
									value={newTitle}
									onChange={(e) => setNewTitle(e.target.value)}
								/>
								<button
									type="button"
									className="btn btn-info"
									onClick={() => setOnChangeTitle(false)}
								>
									Back
								</button>
								<button type="submit" className="btn btn-primary" disabled={localStorage.getItem('demo_cloudatik')}>
									Change Title
								</button>
							</div>
						</form>
					)}
					<LcdDisplay
						type={"bar"}
						index={index}
						unit={unit}
						latestData={latestData}
					/>
					<div
						style={{ width: "100%", minHeight: "200px", height: "25vh" }}
						id={"chartdiv_bar_" + index}
					></div>
				</div>
			</div>
		</div>
	);
}

export default BarChart;
