import { Button, Grid, TextField, Switch } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { steelseries } from '../../Assets/SteelSeries/steelseries'
import RefreshIcon from '@material-ui/icons/Refresh'
import axios from 'axios';
import { useParams } from "react-router-dom";
import { errorToast, successToast } from '../../shared/notification';


let led_pressure

const Pressure = () => {
    const [modalStatus, setModalStatus] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('')
    const { serial, device } = useParams()
    
    const [controllerData, setControllerData] = useState({
        mode: 0,
        pressure: 0,
        low_pressure_setpoint: 0,
        low_pressure_setpoint_delay: 0,
        low_pressure_bypass_setpoint: 0,
        high_pressure_setpoint: 0,
        high_pressure_setpoint_delay: 0,
        high_pressure_bypass_setpoint: 0 
    })


    // LED DISPLAY
    useEffect(() => {
        led_pressure = new steelseries.DisplaySingle('led-pressure', {
            width: 150,
            height: 40,
            value: controllerData.pressure,
            headerString: "PRESSURE (BAR)",
            headerStringVisible: true,
            autoScroll: true,
        })
        led_pressure.setValue(controllerData.pressure || 0)
    }, [controllerData])

    const [telemetryId, setTelemetryId] = useState(null)

    // USEEFFECT MAIN
    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setModalStatus(true)
        setLoadingMessage("Trying to read data from controller")
        console.log("serial", serial)
        // https://api.cloudatik.com/telemetry/v1.0/publish/
        axios.post(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/publish/`, {
            "serial": serial,
            "service":"ikopressure_control",
            "command":"read_data",
            "param": {"value":"all"}
        }, {headers: { 'Authorization': localStorage.getItem('cloudatik-access') },}).then(res => {
            setModalStatus(false);
            console.log("*** res", res)
            if (!res.data["success"]) return errorToast(res.data["message"])
            setTelemetryId(res.data["telemetry id"])
        }).catch(err => {
            console.log("*** err", err)
            errorToast(err);
            setModalStatus(false);
            // window.location.href = "/";
        });
    }
    
    useEffect(() => {
        if (telemetryId) {
            let count = 0;
            setModalStatus(true)
            const fetchData = async () => {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/status/${telemetryId}`, {
                    headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
                })
                
                console.log("response.data", response.data)
        
                if (!response.data.return && count >= 20) {
                    setModalStatus(false)
                    clearAllInterval()
                    return errorToast("Server error")
                } else if (response.data.return && response.data.message == "Value Has Been Set!") {
                    setModalStatus(false)
                    clearAllInterval()
                    getData()
                }  else if (response.data.return && response.data.message == "Data Received!") {
                    setModalStatus(false)
                    clearAllInterval()
                    // console.log(response.data.payload.replace(/'/g, '"'))
                    if (response.data.payload != "{}") {
                        setControllerData(JSON.parse(response.data.payload.replace(/'/g, '"')))
                    }
                }
            }
        
            // Initial call
            fetchData()
            // Set up interval for subsequent calls every 3 seconds
            const intervalId = setInterval(() => {
                count += 3;
                fetchData()
            }, 3000);
            
            // Cleanup function
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [telemetryId])

    function clearAllInterval() {
        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }
    }
    
    
    const [errors, setErrors] = useState({
        low_pressure_setpoint: "",
        low_pressure_setpoint_delay: "",
        low_pressure_bypass_setpoint: "",
        high_pressure_setpoint: "",
        high_pressure_setpoint_delay: "",
        high_pressure_bypass_setpoint: "",
    })


    function onChangeLimit(field, e) {
        console.log("onChangeLimit", field, e.target.value)
        if (field == "low_pressure_setpoint" && (e.target.value > 10 || e.target.value < 0 || e.target.value === "")) {
            setErrors(prev =>({
                ...prev,
                "low_pressure_setpoint": "Min 0, Max 10"
            }))
        } else if (field == "low_pressure_setpoint") {
            setErrors(prev =>({
                ...prev,
                "low_pressure_setpoint": ""
            }))
        }

        if (field == "low_pressure_setpoint_delay" && (e.target.value > 100 || e.target.value < 0 || e.target.value === "")) {
            setErrors(prev =>({
                ...prev,
                "low_pressure_setpoint_delay": "Min 0, Max 100"
            }))
        } else if (field == "low_pressure_setpoint_delay") {
            setErrors(prev =>({
                ...prev,
                "low_pressure_setpoint_delay": ""
            }))
        }

        if (field == "low_pressure_bypass_setpoint" && (e.target.value > 10 || e.target.value < 0 || e.target.value === "")) {
            setErrors(prev =>({
                ...prev,
                "low_pressure_bypass_setpoint": "Min 0, Max 10"
            }))
        } else if (field == "low_pressure_bypass_setpoint") {
            setErrors(prev =>({
                ...prev,
                "low_pressure_bypass_setpoint": ""
            }))
        }

        if (field == "high_pressure_setpoint" && (e.target.value > 10 || e.target.value < 0 || e.target.value === "")) {
            setErrors(prev =>({
                ...prev,
                "high_pressure_setpoint": "Min 0, Max 10"
            }))
        } else if (field == "high_pressure_setpoint") {
            setErrors(prev =>({
                ...prev,
                "high_pressure_setpoint": ""
            }))
        }
        if (field == "high_pressure_setpoint_delay" && (e.target.value > 100 || e.target.value < 0 || e.target.value === "")) {
            setErrors(prev =>({
                ...prev,
                "high_pressure_setpoint_delay": "Min 0, Max 100"
            }))
        } else if (field == "high_pressure_setpoint_delay") {
            setErrors(prev =>({
                ...prev,
                "high_pressure_setpoint_delay": ""
            }))
        }
        if (field == "high_pressure_bypass_setpoint" && (e.target.value > 10 || e.target.value < 0 || e.target.value === "")) {
            setErrors(prev =>({
                ...prev,
                "high_pressure_bypass_setpoint": "Min 0, Max 10"
            }))
        } else if (field == "high_pressure_bypass_setpoint") {
            setErrors(prev =>({
                ...prev,
                "high_pressure_bypass_setpoint": ""
            }))
        }

        setControllerData(prev => (
            {
                ...prev,
                [field]: e.target.value
            }
        ))
    }

    function sendSetting(field) {
        console.log("sendSetting", field, controllerData)
        // console.log({ [field] : controllerData[field] })
        if (
            (Number(controllerData.high_pressure_bypass_setpoint) !== 0 || Number(controllerData.low_pressure_bypass_setpoint) !== 0) &&
            (Number(controllerData.high_pressure_bypass_setpoint) < Number(controllerData.high_pressure_setpoint) ||
            Number(controllerData.high_pressure_setpoint) < Number(controllerData.low_pressure_setpoint) || 
            Number(controllerData.low_pressure_setpoint) < Number(controllerData.low_pressure_bypass_setpoint))
        ) {
            return errorToast("Invalid input")
        }

        setModalStatus(true)
        setLoadingMessage("Sending data to controller")
        axios.post(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/publish/`, {
            "serial": serial,
            "service":"ikopressure_control",
            "command":"set_value",
            "param": { [field] : controllerData[field] }
        }, {headers: { 'Authorization': localStorage.getItem('cloudatik-access') },})
        .then(res => {
            setModalStatus(false)
            if (!res.data["success"]) return errorToast(res.data["message"])
            successToast("Sent. Please refresh and check if setting is updated.")
            console.log("*** res", res)
            // setTelemetryId(res.data["telemetry id"])
        }).catch(err => {
            console.log(err)
            // window.location.href = "/";
        })
    }

    const switchChange = (e, field) => {
        let switchNumeric = e.target.checked ? 1 : 0
        setControllerData(prev=> ({
            ...prev,
            [field]: switchNumeric
        }))

        setModalStatus(true)
        setLoadingMessage("Sending data to controller")
        axios.post(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/publish/`, {
            "serial": serial,
            "service":"ikopressure_control",
            "command":"set_value",
            "param": { [field] : switchNumeric }
        }, {headers: { 'Authorization': localStorage.getItem('cloudatik-access') },})
        .then(res => {
            setModalStatus(false)
            if (!res.data["success"]) return errorToast(res.data["message"])
            successToast("Sent. Please refresh and check if setting is updated.")
            console.log("*** res", res)
            // setTelemetryId(res.data["telemetry id"])
        }).catch(err => {
            console.log(err)
            // window.location.href = "/";
        })
    }
    return (
        <>
        <div className="modal" style={{
            display: (modalStatus ? 'block' : 'none'),
            position: 'fixed',
            zIndex: 1000,
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: "rgba( 255, 255, 255, .8 ) url('https://i.stack.imgur.com/FhHRx.gif') 50% 50% no-repeat",
        }}>
            <p style={{ position: 'absolute', right: '45%', top: '55%' }}>{loadingMessage}</p>
        </div>
        <div style={{ padding: "2rem" }}>
            <Grid container spacing={2} style={{ textAlign: 'center', maxWidth: '750px', margin: 'auto', marginBottom:"5px" }}>
                <Grid item xs={12} sm={3}>
                    <span>Edit/Run Mode</span>
                    <Switch color="primary" checked={Number(controllerData.mode) === 1 ? true : false } onChange={(e) => { switchChange(e, "mode")}}/>
                </Grid>
                <Grid item xs={12} sm={6}><canvas id="led-pressure"></canvas></Grid>
                <Grid item xs={12} sm={3}>
                    <Button  variant="contained"  color="primary" onClick={() => { return window.location.reload(); }} >
                        <RefreshIcon/>
                    </Button>
                </Grid>
            </Grid>
            
            <div className="row" style={{ justifyContent: "space-evenly" }}>
                <div className="col-12 col-sm-5" style={{ border: "1px solid black", borderRadius: "10px", padding: "1rem 3rem", marginBottom: "20px" }}>
                    <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <TextField value={controllerData.low_pressure_setpoint} type="number" error={errors.low_pressure_setpoint == '' ? false : true} helperText={errors.low_pressure_setpoint} style={{ width: '70%' }} label="Lower Limit (BAR)" onChange={(e)=>{onChangeLimit("low_pressure_setpoint", e)}}/>
                        <Button variant="contained" color="primary" style={{ width: '30%', height: "90%", alignSelf: "flex-end" }} onClick={() => { sendSetting("low_pressure_setpoint")} }>SET</Button>
                    </div>

                    <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <TextField value={controllerData.low_pressure_setpoint_delay} type="number" style={{ width: '70%' }} error={errors.low_pressure_setpoint_delay == '' ? false : true} helperText={errors.low_pressure_setpoint_delay} label="Trigger Delay (SEC)" onChange={(e)=>{onChangeLimit("low_pressure_setpoint_delay", e)}}/>
                        <Button variant="contained" color="primary" style={{ width: '30%', height: "90%", alignSelf: "flex-end" }} onClick={() => { sendSetting("low_pressure_setpoint_delay")}}>SET</Button>
                    </div>

                    <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <TextField value={controllerData.low_pressure_bypass_setpoint} type="number" style={{ width: '70%' }} error={errors.low_pressure_bypass_setpoint == '' ? false : true} helperText={errors.low_pressure_bypass_setpoint} label="Lower Bypass Limit (BAR)" onChange={(e)=>{onChangeLimit("low_pressure_bypass_setpoint", e)}}/>
                        <Button variant="contained" color="primary" style={{ width: '30%', height: "90%", alignSelf: "flex-end" }} onClick={() => { sendSetting("low_pressure_bypass_setpoint")}}>SET</Button>
                    </div>
                </div>

                <div className="col-12 col-sm-5" style={{ border: "1px solid black", borderRadius: "10px", padding: "1rem 3rem", marginBottom: "20px" }}>
                    <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <TextField value={controllerData.high_pressure_setpoint} type="number" style={{ width: '70%' }} error={errors.high_pressure_setpoint == '' ? false : true} helperText={errors.high_pressure_setpoint} label="Upper Limit (BAR)" onChange={(e)=>{onChangeLimit("high_pressure_setpoint", e)}}/>
                        <Button variant="contained" color="primary" style={{ width: '30%', height: "90%", alignSelf: "flex-end" }} onClick={() => { sendSetting("high_pressure_setpoint")}}>SET</Button>
                    </div>

                    <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <TextField value={controllerData.high_pressure_setpoint_delay} type="number" style={{ width: '70%' }}  error={errors.high_pressure_setpoint_delay == '' ? false : true} helperText={errors.high_pressure_setpoint_delay} label="Trigger Delay (SEC)" onChange={(e)=>{onChangeLimit("high_pressure_setpoint_delay", e)}}/>
                        <Button variant="contained" color="primary" style={{ width: '30%', height: "90%", alignSelf: "flex-end" }} onClick={() => { sendSetting("high_pressure_setpoint_delay")}}>SET</Button>
                    </div>

                    <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <TextField value={controllerData.high_pressure_bypass_setpoint} type="number" style={{ width: '70%' }}  error={errors.high_pressure_bypass_setpoint == '' ? false : true} helperText={errors.high_pressure_bypass_setpoint} label="Upper Bypass Limit (BAR)" onChange={(e)=>{onChangeLimit("high_pressure_bypass_setpoint", e)}}/>
                        <Button variant="contained" color="primary" style={{ width: '30%', height: "90%", alignSelf: "flex-end" }} onClick={() => { sendSetting("high_pressure_bypass_setpoint")}}>SET</Button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Pressure