import React, {useState} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner';

const Register = () => {
    const [inputs, setInputs] = useState({
        username: '',
        password: '',
        confirm_password: '',
        fullname: '',
        email: '',
        phone: '',
        company: '',
    })
    let history = useHistory()
    const [submitting, setSubmitting] = useState(false)

	// useEffect(() => {
	// 	const loggedInUser = localStorage.getItem("cloudatik");
	// 	if (loggedInUser !== null) {
    //         history.push(`/v2/dashboard`);
	// 	}
	// }, [])

    function handleInput(e, field) {
        setInputs( prev => ({
            ...prev,
            [field]: e.target.value
        }))
    }

    const notifySuccess = (message) => toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:'colored'
    })
    const notifyError = (message) => toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:'colored'
    });

    function submitForm(e) {
        e.preventDefault()
        if (inputs.password != inputs.confirm_password) return notifyError('Password does not match')

        setSubmitting(true)
        axios.post(`${process.env.REACT_APP_API_URL}/user/registration/`, inputs)
        .then(res=>{
            setSubmitting(false)
            notifySuccess(res.data.message)
            history.push("/")
        }).catch(err=>{
            setSubmitting(false)
            console.log(err.response.data)
            if (err.response.data.message) {
                notifyError(err.response.data.message)
            } else {
                notifyError('Something Went Wrong. Please Contact Administrator.')
            }
        })
    }

    return (
        <>
        {submitting && <LoadingSpinner />}
        <div style={{maxWidth: '60vw', margin:'auto', paddingBottom: '30px', paddingTop:'30px'}}>
            <div className="card">
                <div className="card-body">
                        <h3>Sign Up</h3>
                    <form onSubmit={(e)=>submitForm(e)}>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text"
                                className="form-control"
                                placeholder="..."
                                required
                                value={inputs.username}
                                onChange={(e)=>handleInput(e,'username')}
                            />
                        </div>
                        <div className="form-group">
                            <label>Your password</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="..."
                                required
                                value={inputs.password}
                                onChange={(e)=>handleInput(e,'password')}
                            />
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="..."
                                required
                                value={inputs.confirm_password}
                                onChange={(e)=>handleInput(e,'confirm_password')}
                            />
                        </div>
                        <div className="form-group">
                            <label>Fullname</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="..."
                                required
                                value={inputs.fullname}
                                onChange={(e)=>handleInput(e,'fullname')}
                            />
                        </div>
                        
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="..."
                                required
                                value={inputs.email}
                                onChange={(e)=>handleInput(e,'email')}
                            />
                        </div>
                        
                        <div className="form-group">
                            <label>Phone</label>
                            <input type="number"
                                className="form-control"
                                placeholder="..."
                                required
                                value={inputs.phone}
                                onChange={(e)=>handleInput(e,'phone')}
                            />
                        </div>
                        <div className="form-group">
                            <label>Company</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="..."
                                required
                                value={inputs.company}
                                onChange={(e)=>handleInput(e,'company')}
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block" disabled={submitting}>
                                Sign Up
                            </button>
                            {/* <button type="submit" className="btn btn-block" onClick={()=>{history.push('/')}}>
                                Back
                            </button> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}

export default Register