import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  diaglogTitle: {
    textAlign: 'center'
  },
  dialogActions: {
    justifyContent: 'center'
  },
  circularProgress: {
    marginLeft: 10
  }
}));
export default function AlertWriteTimeout({
  isOpen,
  handleClose
}) {

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
    >
      <DialogTitle classes={{ root: classes.diaglogTitle }}>TIMEOUT</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Connection has timed out while writing device settings.
          Please try again.
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={handleClose}
          color="primary"
          autoFocus
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog >
  );
}
