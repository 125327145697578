import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 8,
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: '0 12px'
  },
  explanationLabel: {
    fontSize: 12,
    textAlign: 'center'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 2
  }
}));

export default function OverrideSetting({
  spwet,
  iospwet,
  sppressure,
  handleInputChange
}) {
  const classes = useStyles();

  const spwetInputProps = {
    min: 0,
    max: 100,
    step: 1
  };

  const sppressureInputProps = {
    min: 0,
    max: 10,
    step: 1
  };

  const handleSwitch = (event) => {
    event.target.value = event.target.checked ? 1 : 0;
    handleInputChange(event, 'iospwet');
  };

  return (
    <Box
      border={`1px solid ${grey[300]}`}
      borderRadius={4}
      padding={1}
      marginBottom={2}
    >
      <Box
        display='flex'
        component='h4'
        fontSize='1.2rem'
        alignItems='center'
        justifyContent='center'
        marginBottom={1}
        textAlign='center'
      >
        <FilterHdrIcon classes={{ root: classes.icon }} />
        SOIL MOISTURE OVERRIDE
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <FormControlLabel
          control={
            <Switch
              checked={iospwet === 1 ? true : false}
              onChange={handleSwitch}
              name="iospwet_switch"
              color="primary"
            />
          }
          label={iospwet === 1 ? 'ON' : 'OFF'}
        />
      </Box>
      <Collapse in={iospwet === 1}>
        <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
          <FormLabel component="label" classes={{ root: classes.explanationLabel }}>
            SKIP IRRIGATION IF MORE THAN
          </FormLabel>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <TextField
              id="outlined-basic"
              label="%"
              variant="outlined"
              type="number"
              size='small'
              inputProps={spwetInputProps}
              value={spwet}
              onChange={(event) => handleInputChange(event, "spwet")}
            />
          </Box>
        </FormControl>
      </Collapse >
      <Divider />
      <Box
        display='flex'
        component='h4'
        fontSize='1.2rem'
        alignItems='center'
        justifyContent='center'
        marginBottom={1}
        marginTop={1}
        textAlign='center'
      >
        <FilterDramaIcon classes={{ root: classes.icon }} />
        PRESSURE OVERRIDE
      </Box>
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
        <FormLabel component="label" classes={{ root: classes.explanationLabel }}>
          SKIP IRRIGATION IF MORE THAN
        </FormLabel>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <TextField
            id="outlined-basic"
            label="BAR"
            variant="outlined"
            type="number"
            size='small'
            inputProps={sppressureInputProps}
            value={sppressure}
            onChange={(event) => handleInputChange(event, "sppressure")}
          />
        </Box>
      </FormControl>
    </Box >
  );
}
