import React, { useState } from "react";
import EditProfile from "./EditProfile";
import RemoveNode from "./RemoveNode";
import Box from "@material-ui/core/Box";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function ProfileModal({ device_id, sensor_set, setOpenProfileNode }) {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Edit Profile"/>
                    <Tab label="Remove Profile"/>
                </Tabs>
            </Box>
            <div hidden={value !== 0} id={`simple-tabpanel-${0}`} aria-labelledby={`simple-tab-${0}`}>
                {value === 0 && (
                    <EditProfile device_id={device_id} setOpenProfileNode={setOpenProfileNode}/>
                )}
            </div>
            <div hidden={value !== 1} id={`simple-tabpanel-${1}`} aria-labelledby={`simple-tab-${1}`}>
                {value === 1 && (
                    <RemoveNode device_id={device_id} sensor_set={sensor_set} />
                )}
            </div>
        </Box>
        </>
    );
}

export default ProfileModal;
