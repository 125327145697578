import { Grid} from '@material-ui/core'
import { Button, TextField, Switch } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { errorToast, successToast } from "../../../shared/notification";
import LoadingSpinner from '../../../shared/Spinner/LoadingSpinner';
import { Link, useParams } from "react-router-dom";

const SessionSetting = ({
    sessionSettings, setSessionSettings,
    uploadToController
}) => {

    const handleInputChange=(e, session, field)=>{
        if (session != "end") {
            // RESET NEXT SWITCHES
            if (session == "a") {
                let session_array = ["a","b","c","d","e","f"]
                for (let i in session_array) {
                    resetSwitch(session_array[i])
                }
            } else if (session == "b") {
                let session_array = ["b","c","d","e","f"]
                for (let i in session_array) {
                    resetSwitch(session_array[i])
                }
            } else if (session == "c") {
                let session_array = ["c","d","e","f"]
                for (let i in session_array) {
                    resetSwitch(session_array[i])
                }
            } else if (session == "d") {
                let session_array = ["d","e","f"]
                for (let i in session_array) {
                    resetSwitch(session_array[i])
                }
            } else if (session == "e") {
                let session_array = ["e","f"]
                for (let i in session_array) {
                    resetSwitch(session_array[i])
                }
            } else if (session == "f") {
                let session_array = ["f"]
                for (let i in session_array) {
                    resetSwitch(session_array[i])
                }
            }
        }

        // Inputs Validation
        if (field == 'sc_h') {
            if (e.target.value > 23 || e.target.value < 0) {
                return errorToast('Invalid hours input.')
            }
        } else if (field == 'sc_m') {
            if (e.target.value > 59 || e.target.value < 0) {
                return errorToast('Invalid minutes input.')
            }
        }

        setSessionSettings( prev => ({
            ...prev,
            [session]: {
                ...prev[session],
                [field]: parseInt(e.target.value)
            }
        }))
    }

    function handleEndInputChange() {
        let end_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))
        for (let session of Object.keys(sessionSettings)) {
            if (session != "end") {
                let session_value = parseInt(sessionSettings[session]['sc_h'].toString() + minutePadding(sessionSettings[session]['sc_m'].toString()))
                if ( end_value <= session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [session]: {
                            ...prev[session],
                            "io_sc": 0
                        }
                    }))
                }
            }
        }
    }

    const handleSwitchChange =(session, field)=> {
        if (isNaN(sessionSettings[session]["sc_h"]) || isNaN(sessionSettings[session]["sc_m"])) return alert("Invalid input")
        if (validateSession(session)) {
            if (session=="a") {
                if (sessionSettings[session]["io_sc"] == 1) {
                    // Turning it off
                    let sessions_array = ["b","c","d","e","f","end"]
                    sessions_array.map(s => {
                        setSessionSettings( prev => ({
                            ...prev,
                            [s]: {
                                ...prev[s],
                                [field]: 0
                            }
                        }))
                    })
                }
            }
            if (session == "b") {
                if (sessionSettings["a"]["io_sc"] == 0) {
                    return errorToast(`Invalid switch on 2`)
                } else if (sessionSettings[session]["io_sc"] == 1) {
                    // Turning it off
                    let sessions_array = ["c","d","e","f","end"]
                    sessions_array.map(s => {
                        setSessionSettings( prev => ({
                            ...prev,
                            [s]: {
                                ...prev[s],
                                [field]: 0
                            }
                        }))
                    })
                }
            } else if (session == "c") {
                if (sessionSettings["b"]["io_sc"] == 0) {
                    return errorToast(`Invalid switch on 3`)
                } else if (sessionSettings[session]["io_sc"] == 1) {
                    // Turning it off
                    let sessions_array = ["d","e","f","end"]
                    sessions_array.map(s => {
                        setSessionSettings( prev => ({
                            ...prev,
                            [s]: {
                                ...prev[s],
                                [field]: 0
                            }
                        }))
                    })
                }
            } else if (session == "d") {
                if (sessionSettings["c"]["io_sc"] == 0) {
                    return errorToast(`Invalid switch on 4`)
                } else if (sessionSettings[session]["io_sc"] == 1) {
                    // Turning it off
                    let sessions_array = ["e","f","end"]
                    sessions_array.map(s => {
                        setSessionSettings( prev => ({
                            ...prev,
                            [s]: {
                                ...prev[s],
                                [field]: 0
                            }
                        }))
                    })
                }
            } else if (session == "e") {
                if (sessionSettings["d"]["io_sc"] == 0) {
                    return errorToast(`Invalid switch on 5`)
                } else if (sessionSettings[session]["io_sc"] == 1) {
                    // Turning it off
                    let sessions_array = ["f","end"]
                    sessions_array.map(s => {
                        setSessionSettings( prev => ({
                            ...prev,
                            [s]: {
                                ...prev[s],
                                [field]: 0
                            }
                        }))
                    })
                }
            } else if (session == "f") {
                if (sessionSettings["e"]["io_sc"] == 0) {
                    return errorToast(`Invalid switch on 6`)
                } else if (sessionSettings[session]["io_sc"] == 1) {
                    // Turning it off
                    let sessions_array = ["end"]
                    sessions_array.map(s => {
                        setSessionSettings( prev => ({
                            ...prev,
                            [s]: {
                                ...prev[s],
                                [field]: 0
                            }
                        }))
                    })
                }
            }

            let val = 0
            if (sessionSettings[session][field] == 0) {
                val = 1
            }
            setSessionSettings( prev => ({
                ...prev,
                [session]: {
                    ...prev[session],
                    [field]: val
                }
            }))
        }

    }

    function resetSwitch(session) {
        setSessionSettings( prev => ({
            ...prev,
            [session]: {
                ...prev[session],
                "io_sc": 0
            }
        }))
    }
    function validateSession(selectedSession) {
        if (selectedSession == "a") {
            let session_array = ['b', 'c', 'd', 'e', 'f', 'end']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let next_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))
                // console.log(current_session_value, next_session_value)
                let next_session_switch = parseInt(sessionSettings[session_array[i]]['io_sc'])

                if (session_array[i] == "end" && current_session_value >= next_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value >= next_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
        } else if (selectedSession == "b") {
            let session_array = ['c', 'd', 'e', 'f', 'end']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let next_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))
                // console.log(current_session_value, next_session_value, current_session_value > next_session_value)
                let next_session_switch = parseInt(sessionSettings[session_array[i]]['io_sc'])

                if (session_array[i] == "end" && current_session_value >= next_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value >= next_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
            session_array = ['a']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let previous_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))

                if (session_array[i] == "end" && current_session_value <= previous_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value <= previous_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
        } else if (selectedSession == "c") {
            let session_array = [ 'd', 'e', 'f', 'end']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let next_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))
                let next_session_switch = parseInt(sessionSettings[session_array[i]]['io_sc'])

                if (session_array[i] == "end" && current_session_value >= next_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value >= next_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
            session_array = ['a', 'b']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let previous_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))

                if (session_array[i] == "end" && current_session_value <= previous_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value <= previous_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
        } else if (selectedSession == "d") {
            let session_array = ['e', 'f', 'end']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let next_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))
                let next_session_switch = parseInt(sessionSettings[session_array[i]]['io_sc'])

                if (session_array[i] == "end" && current_session_value >= next_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value >= next_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }

            }
            session_array = ['a', 'b', 'c']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let previous_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))

                if (session_array[i] == "end" && current_session_value <= previous_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value <= previous_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
        } else if (selectedSession == "e") {
            let session_array = ['f', 'end']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let next_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))
                let next_session_switch = parseInt(sessionSettings[session_array[i]]['io_sc'])

                if (session_array[i] == "end" && current_session_value >= next_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value >= next_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
            session_array = ['a', 'b', 'c', 'd']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let previous_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))

                if (session_array[i] == "end" && current_session_value <= previous_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value <= previous_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
        } else if (selectedSession == "f") {
            let session_array = ['end']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let next_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))
                let next_session_switch = parseInt(sessionSettings[session_array[i]]['io_sc'])

                if (session_array[i] == "end" && current_session_value >= next_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value >= next_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
            session_array = ['a', 'b', 'c', 'd', 'e']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let previous_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))

                if (session_array[i] == "end" && current_session_value <= previous_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value <= previous_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
        } else if (selectedSession == "end") {
            let session_array = ['a', 'b', 'c', 'd', 'e', 'f']
            for (let i in session_array) {
                let current_session_value = parseInt(sessionSettings[selectedSession]['sc_h'].toString() + minutePadding(sessionSettings[selectedSession]['sc_m'].toString()))
                let previous_session_value = parseInt(sessionSettings[session_array[i]]['sc_h'].toString() + minutePadding(sessionSettings[session_array[i]]['sc_m'].toString()))

                if (session_array[i] == "end" && current_session_value <= previous_session_value) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                } else if (current_session_value <= previous_session_value && sessionSettings[session_array[i]]['io_sc'] == 1) {
                    setSessionSettings( prev => ({
                        ...prev,
                        [selectedSession]: {
                            ...prev[selectedSession],
                            "io_sc": 0
                        }
                    }))
                    errorToast(`Invalid input`)
                    return false
                }
            }
        }
        
        return true
    }

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} style={{margin:'1rem 0'}}>
                <h3>Sessions Time Setting</h3>
            </Grid>

            <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                    <h5>Session 1</h5>
                </div>
                <div className="col-12 col-sm-4 mb-3">
                    <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["a"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'a', 'sc_h')}}/>
                    <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["a"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'a', 'sc_m')}}/>
                </div>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                    Disable
                    <Switch color="primary" checked={sessionSettings["a"]["io_sc"] == 0 ? false : true} value={sessionSettings["a"]["io_sc"]} onClick={(e)=>{handleSwitchChange('a', 'io_sc')}}/>
                    Enable
                </div>
            </div>

            
            <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                    <h5>Session 2</h5>
                </div>
                <div className="col-12 col-sm-4 mb-3">
                    <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["b"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'b', 'sc_h')}}/>
                    <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["b"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'b', 'sc_m')}}/>
                </div>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                    Disable
                    <Switch color="primary" checked={sessionSettings["b"]["io_sc"] == 0 ? false : true} value={sessionSettings["b"]["io_sc"]} onClick={(e)=>{handleSwitchChange('b', 'io_sc')}}/>
                    Enable
                </div>
            </div>

            
            <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                    <h5>Session 3</h5>
                </div>
                <div className="col-12 col-sm-4 mb-3">
                    <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["c"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e,'c', 'sc_h')}}/>
                    <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["c"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e,'c', 'sc_m')}}/>
                </div>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                    Disable
                    <Switch color="primary" checked={sessionSettings["c"]["io_sc"] == 0 ? false : true} value={sessionSettings["c"]["io_sc"]} onClick={(e)=>{handleSwitchChange('c', 'io_sc')}}/>
                    Enable
                </div>
            </div>



            <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                    <h5>Session 4</h5>
                </div>
                <div className="col-12 col-sm-4 mb-3">
                    <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["d"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'd', 'sc_h')}}/>
                    <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["d"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e,'d', 'sc_m')}}/>
                </div>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                    Disable
                    <Switch color="primary" checked={sessionSettings["d"]["io_sc"] == 0 ? false : true} value={sessionSettings["d"]["io_sc"]} onClick={(e)=>{handleSwitchChange('d', 'io_sc')}}/>
                    Enable
                </div>
            </div>


            <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                    <h5>Session 5</h5>
                </div>
                <div className="col-12 col-sm-4 mb-3">
                    <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["e"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'e', 'sc_h')}}/>
                    <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["e"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'e', 'sc_m')}}/>
                </div>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                    Disable
                    <Switch color="primary" checked={sessionSettings["e"]["io_sc"] == 0 ? false : true} value={sessionSettings["e"]["io_sc"]} onClick={(e)=>{handleSwitchChange('e', 'io_sc')}}/>
                    Enable
                </div>
            </div>

            
            <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                    <h5>Session 6</h5>
                </div>
                <div className="col-12 col-sm-4 mb-3">
                    <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["f"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'f', 'sc_h')}}/>
                    <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["f"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'f', 'sc_m')}}/>
                </div>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                    Disable
                    <Switch color="primary" checked={sessionSettings["f"]["io_sc"] == 0 ? false : true} value={sessionSettings["f"]["io_sc"]} onClick={(e)=>{handleSwitchChange('f', 'io_sc')}}/>
                    Enable
                </div>
            </div>
            
            
            <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                    <h5>End Session</h5>
                </div>
                <div className="col-12 col-sm-4">
                    <TextField onBlur={()=>{handleEndInputChange()}} label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["end"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'end', 'sc_h')}}/>
                    <TextField onBlur={()=>{handleEndInputChange()}} label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["end"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'end', 'sc_m')}}/>
                </div>
                <div className="col-12 col-sm-4" style={{visibility: 'hidden'}}></div>
            </div>
            
            <Grid item xs={12}>
                <Button color='warning' fullWidth variant="contained" onClick={()=>{
                    for (let ses in sessionSettings) {
                        if (isNaN(sessionSettings[ses]["sc_h"]) || isNaN(sessionSettings[ses]["sc_m"])) {
                            return alert("Invalid Input")
                        }
                    }
                    // setTimeout(()=>{
                    uploadToController()
                    // }, 1500)
                }}>Upload to Controller</Button>
            </Grid>
        </Grid>
        
        </>
    )
}

const minutePadding =(min)=>{
    if (min.toString().length < 2) {
        return '0' + min.toString()
    }
    return min.toString()
}

export default SessionSetting