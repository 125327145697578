import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute(props) {
  return (
    <>
      {props.auth ? (
        <Route path={props.path} component={() => <props.comp />} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default PrivateRoute;
