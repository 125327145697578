import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {
  DataGrid
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import AlertSnackbar from './AlertSnackbar';
import CustomToolbar from './CustomToolbar';
import ScheduleForm from './ScheduleForm';
import { refetchApi } from '../../shared/utils';

const DISPLAY = {
  table: 'table',
  newForm: 'newForm',
  editForm: 'editForm'
};

const useStyles = makeStyles({
  gridContainer: {
    minHeight: 500,
  },
  dataGrid: {
    backgroundColor: "#fff",
    padding: 10
  },
  columnHeader: {
    fontWeight: 'bold',
  }
});

export default function RemoteSchedule() {
  const classes = useStyles();

  const { device, device_id } = useParams();

  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const readSchedule = () => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/getRemoteScheduleAF/${device_id}/`,
      {
        headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.code && result.code === 'token_not_valid') {
            refetchApi();
          }

          setSchedules(result.schedule);
          setIsLoading(false);
        },
      );
  };

  const deleteSchedule = (payload) => {
    setIsLoading(true);
    setWriteSettingsResult(null);
    fetch(`${process.env.REACT_APP_API_URL}/deleteRemoteScheduleAF/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': localStorage.getItem('cloudatik-access')
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setWriteSettingsResult('success');
        } else {
          setWriteSettingsResult('error');
        }
        setIsLoading(false);
        readSchedule();
      });
  };

  useEffect(() => {
    readSchedule();
  }, []);

  const writeScheduleSettings = (payload, method) => {

    const postUrl = `${process.env.REACT_APP_API_URL}/saveRemoteScheduleAF/${device_id}/`;
    const putUrl = `${process.env.REACT_APP_API_URL}//updateRemoteScheduleAF/`;
    const fetchUrl = method === 'POST' ? postUrl : putUrl;

    setIsLoading(true);
    setWriteSettingsResult(null);
    fetch(fetchUrl, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        'Authorization': localStorage.getItem('cloudatik-access')
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setWriteSettingsResult('success');
        } else {
          setWriteSettingsResult('error');
        }

        setIsLoading(false);
      });
  };

  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };
  const [scheduleSettings, setScheduleSettings] = useState({});
  const [writeSettingsResult, setWriteSettingsResult] = useState(null);

  const handleClickEditButton = (id) => {
    setScheduleSettings(schedules.find(s => s.id === id));
    setCurrentDisplay(DISPLAY.editForm);
  };


  const columns = [
    { field: 'schedule', headerName: 'Schedule', width: 150 },
    {
      field: 'session',
      headerName: 'Session',
      width: 150,
      editable: false,
    },
    {
      field: 'block',
      headerName: 'Block',
      width: 120,
      editable: false,
    },
    {
      field: 'medium',
      headerName: 'Type',
      type: 'singleSelect',
      width: 150,
      editable: false,
    },
    {
      field: 'spec',
      headerName: 'EC Setpoint',
      type: 'number',
      width: 150,
      editable: false,
      renderCell: (params) => (
        params.getValue(params.id, 'medium') === 'Water' ? '--' : params.value
      ),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      type: 'number',
      width: 150,
      editable: false
    },
    {
      field: 'success',
      headerName: 'System Updated',
      type: 'boolean',
      width: 200,
      editable: false,
      renderCell: (params) => (
        params.value === true ?
          <CheckIcon />
          :
          <ScheduleIcon />
      ),
    },
    {
      field: 'id',
      headerName: 'Edit',
      type: 'boolean',
      width: 200,
      editable: false,
      renderCell: (params) => (
        params.getValue(params.id, 'success') === true ? '--' :
          <IconButton
            onClick={() => handleClickEditButton(params.value)}
            disabled={isLoading}
          >
            <EditIcon />
          </IconButton>
      ),
    },
  ];


  const [currentDisplay, setCurrentDisplay] = useState(DISPLAY.table);

  const switchDisplay = (targetDisplay) => {
    setWriteSettingsResult(null);
    setCurrentDisplay(targetDisplay);
    if (targetDisplay === DISPLAY.table) {
      readSchedule();
    }
  };

  let displayedItem = null;

  switch (currentDisplay) {
    case DISPLAY.table:
      displayedItem =
        <DataGrid
          rows={schedules}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          classes={{ root: classes.dataGrid, columnHeader: classes.columnHeader }}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              scheduleIds: selectionModel,
              deleteSchedule: deleteSchedule,
              switchDisplay: switchDisplay,
              readSchedule: readSchedule,
              isLoading: isLoading
            }
          }}
          onSelectionModelChange={handleSelectionModelChange}
          selectionModel={selectionModel}
          loading={isLoading}
        />;
      break;
    case DISPLAY.newForm:
      displayedItem =
        <ScheduleForm
          serial={device.toUpperCase()}
          writeScheduleSettings={writeScheduleSettings}
          switchDisplay={switchDisplay}
          isLoading={isLoading}
          writeSettingsResult={writeSettingsResult}
        />;
      break;
    case DISPLAY.editForm:
      displayedItem =
        <ScheduleForm
          isEdit
          serial={device.toUpperCase()}
          writeScheduleSettings={writeScheduleSettings}
          scheduleSettings={scheduleSettings}
          switchDisplay={switchDisplay}
          isLoading={isLoading}
          writeSettingsResult={writeSettingsResult}
        />;
      break;
  }

  return (
    <section>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12}>
          {displayedItem}
        </Grid>
      </Grid>
      <AlertSnackbar writeSettingsResult={writeSettingsResult} />
    </section>
  );
};
