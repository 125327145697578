import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import useTimer from '../../hook/useTimer';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  diaglogTitle: {
    textAlign: 'center'
  },
  dialogActions: {
    justifyContent: 'center'
  },
  circularProgress: {
    marginLeft: 10
  }
}));
export default function AlertTimeSettings({
  isOpen,
  handleClose,
}) {

  const { timer, handleTimerStart, handleTimerReset } = useTimer();

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (timer === 3) {
      handleTimerReset();
      setIsLoading(false);
      handleClose();
    }
  }, [timer]);

  const handleClickRefreshButton = () => {
    setIsLoading(true);
    handleTimerStart();
  };

  return (
    <Dialog
      open={isOpen}
    >
      <DialogTitle classes={{ root: classes.diaglogTitle }}>DISCLAIMER</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Time settings were sent but they may not be accepted by the device due to misconfigurations.
          Please refresh and verify the actual settings set on the device.
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={handleClickRefreshButton}
          color="primary"
          autoFocus
          variant="contained"
          disabled={isLoading}
        >
          REFRESH {isLoading && <CircularProgress size={12} classes={{ root: classes.circularProgress }} />}
        </Button>
      </DialogActions>
    </Dialog >
  );
}
