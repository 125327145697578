import React, { useEffect, useState } from "react";
import * as steelseries from "steelseries";
import { formatDate } from "../../shared/DateTimeConvertion";
import { ButtonGroup } from "reactstrap";

function Header({
	tag_name,
	serial,
	sensor_set,
	last_update,
	nodeIsActive,
	resetDateAxis,
}) {
	const [buttonActive, setButtonActive] = useState("");
	const [time, setTime] = useState(last_update);

	useEffect(() => {
		setTime(last_update);
	}, [last_update]);

	useEffect(() => {
		let led = new steelseries.Led(document.getElementById("canvas_LED"), {
			size: 40,
		});
		if (nodeIsActive) {
			led.setLedColor(steelseries.LedColor.GREEN_LED);
		} else {
			led.setLedColor(steelseries.LedColor.RED_LED);
		}
		led.setLedOnOff(true);
	}, [nodeIsActive]);

	useEffect(() => {
		reset_xAxis(0, "btn_12hrs");
	}, []);

	function reset_xAxis(cat, button_id) {
		// cat 0 = 12 Hours
		// cat 1 = 1 day
		// cat 2 = 2 day
		// cat 3 = 3 day
		let endTime;
		var btn_arr = ["btn_12hrs", "btn_24hrs", "btn_36hrs", "btn_48hrs"];
		for (let index = 0; index < btn_arr.length; index++) {
			if (btn_arr[index] == button_id) {
				document
					.getElementById(btn_arr[index])
					.setAttribute("class", "btn btn-info");
			} else {
				document
					.getElementById(btn_arr[index])
					.setAttribute("class", "btn btn-primary");
			}
		}
		setButtonActive(buttonActive);

		if (cat == 0) {
			endTime = formatDate(
				12
			);
			resetDateAxis(endTime);
		}
		if (cat == 1) {
			endTime = formatDate(
				24
			);
			resetDateAxis(endTime);
		}
		if (cat == 2) {
			endTime = formatDate(
				48
			);
			resetDateAxis(endTime);
		}
		if (cat == 3) {
			endTime = formatDate(
				72
			);
			resetDateAxis(endTime);
		}
	}

	return (
		<>
			<h5>
				{tag_name}
				<br />
				{serial} [{sensor_set}]
			</h5>
			<canvas id="canvas_LED"></canvas>
			<h6>
				Last Update:
				<br />
				{time}
			</h6>
			<div className="row">
				<div className="col">
					<ButtonGroup>
						<button
							className="btn btn-primary"
							id="btn_12hrs"
							onClick={() => reset_xAxis(0, "btn_12hrs")}
						>
							12 Hours
						</button>
						<button
							className="btn btn-primary"
							id="btn_24hrs"
							onClick={() => reset_xAxis(1, "btn_24hrs")}
						>
							1 Day
						</button>
						<button
							className="btn btn-primary"
							id="btn_36hrs"
							onClick={() => reset_xAxis(2, "btn_36hrs")}
						>
							2 Days
						</button>
						<button
							className="btn btn-primary"
							id="btn_48hrs"
							onClick={() => reset_xAxis(3, "btn_48hrs")}
						>
							3 Days
						</button>
					</ButtonGroup>
				</div>
			</div>
		</>
	);
}

export default Header;
