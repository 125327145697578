import React, { useState, useEffect } from "react";
import alarm from "../../../services/node/alarm";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";

function AlarmHistory({ device_id }) {
    const [alarmList, setAlarmList] = useState([]);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        alarm
            .getInstance()
            .getAlarmList(device_id)
            .then((result) => {
                if (result) {
                    setAlarmList(result["alarm_list"]);
                    setTitle(result["serial"]);
                    setLoading(false);
                }
            });
    }, []);

    return (
        <div>
            {loading && <LoadingSpinner />}
            <div>
                {alarmList.length > 0 ? (
                    <table className="table" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Alarm Time</th>
                                <th>Serial</th>
                                <th>Parameter</th>
                                <th>Value</th>
                                <th>Lower Limit</th>
                                <th>Higher Limit</th>
                                <th>Acknowledged By</th>
                                <th>Acknowledged Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alarmList.map((item) => (
                                <tr>
                                    <td>{item.alarm_datetime}</td>
                                    <td>{item.serial}</td>
                                    <td>{item.label}</td>
                                    <td>{item.value}</td>
                                    <td>{item.limitLower}</td>
                                    <td>{item.limitUpper}</td>
                                    <td>{item.user_removal}</td>
                                    <td>{item.ack_datetime}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <h3>No Alarm Data</h3>
                )}
            </div>
        </div>
    );
}

export default AlarmHistory;
