import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import "./LoadingSpinner.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function LoadingSpinner() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div id="cover-spin"></div>
    </>
  );
}
