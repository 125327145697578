import React, { useEffect, useState } from "react";
import nodeProp from "../../services/node/node-props";
import Slider from "../Slider";
import Switch from "../Switch";
import Threshold from "../Threshold";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import PropSlider from "./PropSlider";
import { successToast } from "../../shared/notification";

const NodeProps = ({ device_id }) => {
    const [propSliderData, setPropSliderData] = useState([]);
    const [propThresholdData, setPropThresholdData] = useState([]);
    const [propSwitchData, setPropSwitchData] = useState([]);

    const [propComSliderData, setPropComSliderData] = useState([]);
    const [propComThresholdData, setPropComThresholdData] = useState([]);
    const [propComSwitchData, setPropComSwitchData] = useState([]);

    const [mainSingleData, setMainSingleData] = useState([]);
    const [mainCombinedData, setMainCombinedData] = useState([]);

    const [loading, setLoading] = useState(false);

    function updateValue(data, multi) {
        if (parseInt(multi) == 0) {
            for (let index = 0; index < mainSingleData.length; index++) {
                if (mainSingleData[index]["parameter"] == data["parameter"]) {
                    mainSingleData[index]["enabled"] = data["enabled"];
                    mainSingleData[index]["limit_low"] = data["limit_low"];
                    mainSingleData[index]["limit_high"] = data["limit_high"];
                    mainSingleData[index]["alarm_min"] = data["alarm_min"];
                    mainSingleData[index]["alarm_max"] = data["alarm_max"];
                    mainSingleData[index]["plot_limit"] = data["plot_limit"];
                    mainSingleData[index]["label"] = data["label"];
                    return;
                }
            }
            mainSingleData.push(data);
        }
        else {
            for (let index = 0; index < mainCombinedData.length; index++) {
                if (mainCombinedData[index]["parameter"] == data["parameter"]) {
                    mainCombinedData[index]["enabled"] = data["enabled"];
                    mainCombinedData[index]["limit_low"] = data["limit_low"];
                    mainCombinedData[index]["limit_high"] = data["limit_high"];
                    mainCombinedData[index]["alarm_min"] = data["alarm_min"];
                    mainCombinedData[index]["alarm_max"] = data["alarm_max"];
                    mainCombinedData[index]["plot_limit"] = data["plot_limit"];
                    mainCombinedData[index]["label"] = data["label"];
                    return;
                }
            }
            mainCombinedData.push(data);
        }
    }

    function onUpdateValue() {
        setLoading(true);
        let dataToSubmit = {};
        dataToSubmit["single"] = mainSingleData;
        dataToSubmit["combined"] = mainCombinedData;

        nodeProp
            .getInstance()
            .updateAlarmProps(dataToSubmit)
            .then((result) => {
                successToast(result["message"]);
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);
        nodeProp
            .getInstance()
            .getAlarmProps(device_id)
            .then((result) => {
                setPropSliderData(result["single"]["slider"]);
                setPropSwitchData(result["single"]["switch"]);
                setPropThresholdData(result["single"]["threshold"]);
                setPropComSliderData(result["combined"]["slider"]);
                setPropComSwitchData(result["combined"]["switch"]);
                setPropComThresholdData(result["combined"]["threshold"]);
                setLoading(false);
            });
    }, []);

    return (
        <>
            {loading && <LoadingSpinner />}
            {
                propComSliderData.map((item, index) => (
                    <div>
                        <PropSlider index={"_multi" + index}
                            multi={1}
                            device_id={device_id}
                            alarm_enabled={item.alarm_enabled}
                            plot_limit={item.plot_limit}
                            alarm_min={item.alarm_min}
                            alarm_max={item.alarm_max}
                            slider_min={item.slider_min}
                            slider_max={item.slider_max}
                            slide_mix={item.slide_mix}
                            slide_max={item.slide_max}
                            parameter={item.parameter}
                            label={item.label}
                            limit_high={item.limit_high}
                            updateValue={updateValue}
                            sensor_set={item.sensor_set}
                            slider_step={item.slider_step}
                            limit_low={item.limit_low} />
                        <br />
                    </div>
                ))
            }
            {
                propComSwitchData.map((item, index) => (
                    <div>
                        <Switch index={"_multi" + index}
                            multi={1}
                            device_id={device_id}
                            alarm_enabled={item.alarm_enabled}
                            plot_limit={item.plot_limit}
                            alarm_min={item.alarm_min}
                            alarm_max={item.alarm_max}
                            slider_min={item.slider_min}
                            slider_max={item.slider_max}
                            slide_mix={item.slide_mix}
                            slide_max={item.slide_max}
                            parameter={item.parameter}
                            label={item.label}
                            limit_high={item.limit_high}
                            updateValue={updateValue}
                            sensor_set={item.sensor_set}
                            limit_low={item.limit_low} />
                        <br />
                    </div>
                ))
            }
            {
                propComThresholdData.map((item, index) => (
                    <div>
                        <Threshold index={"_multi" + index}
                            multi={1}
                            device_id={device_id}
                            alarm_enabled={item.alarm_enabled}
                            plot_limit={item.plot_limit}
                            alarm_min={item.alarm_min}
                            alarm_max={item.alarm_max}
                            slider_min={item.slider_min}
                            slider_max={item.slider_max}
                            slide_mix={item.slide_mix}
                            slide_max={item.slide_max}
                            parameter={item.parameter}
                            label={item.label}
                            limit_high={item.limit_high}
                            updateValue={updateValue}
                            sensor_set={item.sensor_set}
                            limit_low={item.limit_low} />
                        <br />
                    </div>
                ))
            }
            {
                propSliderData.map((item, index) => (
                    <div>
                        <PropSlider index={index}
                            multi={0}
                            device_id={device_id}
                            alarm_enabled={item.alarm_enabled}
                            plot_limit={item.plot_limit}
                            alarm_min={item.alarm_min}
                            alarm_max={item.alarm_max}
                            slider_min={item.slider_min}
                            slider_max={item.slider_max}
                            slide_mix={item.slide_mix}
                            slide_max={item.slide_max}
                            parameter={item.parameter}
                            label={item.label}
                            limit_high={item.limit_high}
                            updateValue={updateValue}
                            sensor_set={item.sensor_set}
                            slider_step={item.slider_step}
                            limit_low={item.limit_low} />
                        <br />
                    </div>
                ))
            }
            {
                propSwitchData.map((item, index) => (
                    <div>
                        <Switch index={index}
                            multi={0}
                            device_id={device_id}
                            alarm_enabled={item.alarm_enabled}
                            plot_limit={item.plot_limit}
                            alarm_min={item.alarm_min}
                            alarm_max={item.alarm_max}
                            slider_min={item.slider_min}
                            slider_max={item.slider_max}
                            slide_mix={item.slide_mix}
                            slide_max={item.slide_max}
                            parameter={item.parameter}
                            label={item.label}
                            limit_high={item.limit_high}
                            updateValue={updateValue}
                            sensor_set={item.sensor_set}
                            limit_low={item.limit_low} />
                        <br />
                    </div>
                ))
            }
            {
                propThresholdData.map((item, index) => (
                    <div>
                        <Threshold index={index}
                            multi={0}
                            device_id={device_id}
                            alarm_enabled={item.alarm_enabled}
                            plot_limit={item.plot_limit}
                            alarm_min={item.alarm_min}
                            alarm_max={item.alarm_max}
                            slider_min={item.slider_min}
                            slider_max={item.slider_max}
                            slide_mix={item.slide_mix}
                            slide_max={item.slide_max}
                            parameter={item.parameter}
                            label={item.label}
                            limit_high={item.limit_high}
                            updateValue={updateValue}
                            sensor_set={item.sensor_set}
                            limit_low={item.limit_low} />
                        <br />
                    </div>
                ))
            }
            <button className="btn btn-primary" type={"button"} onClick={() => onUpdateValue()} disabled={localStorage.getItem("cloudatik_demo")}>Save</button>
        </>
    );
};

export default NodeProps;
