import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import grey from '@material-ui/core/colors/grey';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from 'react';
import AlertSnackbar from './AlertSnackbar';
import PumpHouse from './PumpHouse';
import StatusCard from './StatusCard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    margin: '0 12px'
  },
  modeButton: {
    margin: '0 12px 8px'
  },
  select: {
    paddingLeft: '32px',
    border: `1px solid ${grey[50]}`
  },
  heading: {
    fontSize: '1.2rem',
    marginBottom: 0,
    width: '100%',
    textAlign: 'center'
  },
  menuItem: {
    fontSize: '1.2rem',
    marginBottom: 0,
    textAlign: 'center'
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: '16px',
      fontSize: '1.2rem'
    }
  },
  alert: {
    marginBottom: 12
  }
}));

const pumpHouse = {
  one: {
    name: 'PUMP HOUSE 1',
    id: '1'
  },
  two: {
    name: 'PUMP HOUSE 2',
    id: '2'
  }
};

export default function ManualOperation({
  mqttClient,
  manualSettings,
  readManualSettings,
  writeManualSettings,
  isWriteSettingsInitiated,
  isReadSettingsInitiated,
  writeSettingsResult
}) {

  const [inputSettings, setInputSettings] = useState({
    mode: '',
    medium1: '',
    spec1: '',
    phpump1: '',
    block1: '',
    pump1: '',
    start1: '',
    medium2: '',
    spec2: '',
    phpump2: '',
    block2: '',
    pump2: '',
    start2: '',
  });

  const [isConnected, setIsConnected] = useState(false);
  const [currentPumpHouse, setCurrentPumpHouse] = useState(pumpHouse.one);
  const [deviceMode, setDeviceMode] = useState('Loading...');
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [isDisplayPumphouseSettings, setIsDisplayPumphouseSettings] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (mqttClient) {
      setIsConnected(true);

      const payload = {
        messageId: "rdmn",
        devices: "manual",
        mode: 2
      };
      readManualSettings(payload);
    } else {
      setIsConnected(false);
    }
  }, [mqttClient]);

  useEffect(() => {
    if (manualSettings) {
      const deviceData = manualSettings.deviceData;

      switch (deviceData.mode) {
        case 0:
          setDeviceMode('Auto Mode');
          setIsDisplayPumphouseSettings(false);
          break;
        case 1:
          setDeviceMode('Manual Mode');
          setIsDisplayPumphouseSettings(true);
          break;
      }

      setInputSettings({
        mode: deviceData.mode,
        medium1: deviceData.medium1,
        spec1: deviceData.spec1,
        phpump1: deviceData.phpump1,
        block1: deviceData.block1,
        pump1: deviceData.pump1,
        start1: deviceData.start1,
        medium2: deviceData.medium2,
        spec2: deviceData.spec2,
        phpump2: deviceData.phpump2,
        block2: deviceData.block2,
        pump2: deviceData.pump2,
        start2: deviceData.start2,
      });
    }
  }, [manualSettings]);

  const handleSelect = (event) => {

    const selectedPumpHouseName = event.target.value;

    switch (selectedPumpHouseName) {
      case pumpHouse.one.name:
        setCurrentPumpHouse(pumpHouse.one);
        break;
      case pumpHouse.two.name:
        setCurrentPumpHouse(pumpHouse.two);
        break;
    }
  };

  const handleClickSendButton = () => {

    const payload = {
      messageId: `wrmn`,
      devices: [{
        deviceId: "manual",
        deviceData: {
          [`medium${currentPumpHouse.id}`]: inputSettings[`medium${currentPumpHouse.id}`],
          [`spec${currentPumpHouse.id}`]: inputSettings[`spec${currentPumpHouse.id}`],
          [`phpump${currentPumpHouse.id}`]: inputSettings[`phpump${currentPumpHouse.id}`],
          [`block${currentPumpHouse.id}`]: inputSettings[`block${currentPumpHouse.id}`],
          [`pump${currentPumpHouse.id}`]: inputSettings[`pump${currentPumpHouse.id}`],
          [`start${currentPumpHouse.id}`]: inputSettings[`start${currentPumpHouse.id}`],
        }
      }]
    };
    writeManualSettings(payload);
  };

  const handleClickSwitchButton = () => {
    const payload = {
      messageId: `wrmn`,
      devices: [{
        deviceId: "manual",
        deviceData: {
          mode: inputSettings.mode
        }
      }]
    };
    writeManualSettings(payload);
  };

  const handleClickRefreshButton = () => {
    const payload = {
      messageId: "rdmn",
      devices: "manual",
      mode: 2
    };
    readManualSettings(payload);
  };

  const handleInputChange = (event, param) => {
    let value = parseFloat(event.target.value);

    setInputSettings(settings => {
      return {
        ...settings,
        [param]: value
      };
    });
  };

  useEffect(() => {
    if (writeSettingsResult === 'success') {
      handleClickRefreshButton();
    }
  }, [writeSettingsResult]);

  return (
    <section className={classes.root}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} lg={4}>
              <StatusCard isConnected={isConnected} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
            <CardContent>
              <Box border={`1px solid ${grey[300]}`} borderRadius='4px' padding='12px' marginBottom='16px'>
                <Typography variant="h6" align='center'>
                  {deviceMode}
                </Typography>
              </Box>
              {isDisplayPumphouseSettings &&
                <Alert severity="warning" classes={{ root: classes.alert }}>
                  <AlertTitle>Warning</AlertTitle>
                  Please switch to <strong>Auto Mode</strong> after use.
                </Alert>
              }
              <Box
                border={`1px solid ${grey[300]}`}
                borderRadius={4}
                padding={1}
                marginBottom={2}
              >
                <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
                  <FormLabel component="legend">MODE</FormLabel>
                  <RadioGroup
                    value={inputSettings.mode}
                    onChange={(event) => handleInputChange(event, 'mode')}
                  >
                    <FormControlLabel value={0} control={<Radio color="primary" />} label="AUTO" />
                    <FormControlLabel value={1} control={<Radio color="primary" />} label="MANUAL" />
                  </RadioGroup>
                </FormControl>
                <Box display='flex' justifyContent='center' alignItems='center' flexWrap='wrap'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickSwitchButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.modeButton }}
                    startIcon={<SendIcon />}
                  >
                    SWITCH
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickRefreshButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.modeButton }}
                    startIcon={<RefreshIcon />}
                  >
                    REFRESH
                  </Button>
                </Box>
              </Box>
              {isDisplayPumphouseSettings &&
                <>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      id="pump-house-select"
                      value={currentPumpHouse.name}
                      onChange={handleSelect}
                      classes={{ root: classes.select }}
                      disabled={isWriteSettingsInitiated || isReadSettingsInitiated}
                    >
                      <MenuItem
                        value={pumpHouse.one.name}
                        classes={{ root: classes.menuItem }}
                      >
                        <h3 className={classes.heading}>
                          {pumpHouse.one.name}
                        </h3>
                      </MenuItem>
                      <MenuItem
                        value={pumpHouse.two.name}
                        classes={{ root: classes.menuItem }}
                      >
                        <h3 className={classes.heading}>
                          {pumpHouse.two.name}
                        </h3>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <form noValidate autoComplete="off">
                    <PumpHouse
                      pumpHouseId={currentPumpHouse.id}
                      medium={inputSettings[`medium${currentPumpHouse.id}`]}
                      spec={inputSettings[`spec${currentPumpHouse.id}`]}
                      phpump={inputSettings[`phpump${currentPumpHouse.id}`]}
                      block={inputSettings[`block${currentPumpHouse.id}`]}
                      pump={inputSettings[`pump${currentPumpHouse.id}`]}
                      start={inputSettings[`start${currentPumpHouse.id}`]}
                      handleInputChange={handleInputChange}
                    />
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickSendButton}
                        disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                        classes={{ root: classes.button }}
                        startIcon={<SendIcon />}
                      >
                        SEND
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickRefreshButton}
                        disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                        classes={{ root: classes.button }}
                        startIcon={<RefreshIcon />}
                      >
                        REFRESH
                      </Button>
                    </Box>
                  </form>
                </>
              }
              <AlertSnackbar writeSettingsResult={writeSettingsResult} />
            </CardContent>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
          </Card >
        </Grid>
      </Grid>
    </section>
  );
}
