import { Button, IconButton, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { steelseries } from '../Assets/SteelSeries/steelseries';
import { errorToast, successToast } from '../shared/notification';

var regExp = /[a-zA-Z]/g;

var mqtt = require('mqtt');
let device_response = null;

let sphec_factor, splec_factor, sphph_factor, splph_factor;

let led_ec, led_ph, led_wt, led_message;
let message_list = [];

const mqtt_username = process.env.REACT_APP_MQTT_USERNAME;
const mqtt_password = process.env.REACT_APP_MQTT_PASSWORD;
const mqtt_server = process.env.REACT_APP_MQTT_SERVER;

let connection_time = 0;

export default function Controller() {
    const [modalStatus, setModalStatus] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Connecting');
    const { device_id, telemetry } = useParams();

    // DISPLAY
    const [message, setMessage] = useState('');
    const [ec, setEc] = useState({
        'label': '',
        'unit': '',
        'value': '',
        'factor': ''
    });
    const [wt, setWt] = useState({
        'label': '',
        'unit': '',
        'value': '',
        'factor': ''
    });
    const [ph, setPh] = useState({
        'label': '',
        'unit': '',
        'value': '',
        'factor': ''
    });

    // SWITCH
    const [status, setStatus] = useState({
        'label': '',
        'value': 0,
        'input_device_name': '',
        'input_parameter': '',
        'input_value': '',
    });
    const [psa, setPsa] = useState({
        'label': '',
        'value': 0,
        'input_device_name': '',
        'input_parameter': '',
        'input_value': '',
    });
    const [psb, setPsb] = useState({
        'label': '',
        'value': 0,
        'input_device_name': '',
        'input_parameter': '',
        'input_value': '',
    });
    const [psc, setPsc] = useState({
        'label': '',
        'value': 0,
        'input_device_name': '',
        'input_parameter': '',
        'input_value': '',
    });
    const [osa, setOsa] = useState({
        'label': '',
        'value': 0,
        'input_device_name': '',
        'input_parameter': '',
        'input_value': '',
    });
    const [addcl, setAddcl] = useState({
        'label': '',
        'value': 0,
        'input_device_name': '',
        'input_parameter': '',
        'input_value': '',
    });

    //TEXT INPUT
    const [sphec, setSphec] = useState({
        'label': '',
        'value': '',
        'factor': '',
        'input_device_name': '',
        'input_parameter': '',
        'input_max': '',
        'input_min': '',
    });
    const [splec, setSplec] = useState({
        'label': '',
        'value': '',
        'factor': '',
        'input_device_name': '',
        'input_parameter': '',
        'input_max': '',
        'input_min': '',
    });
    const [sphph, setSphph] = useState({
        'label': '',
        'value': '',
        'factor': '',
        'input_device_name': '',
        'input_parameter': '',
        'input_max': '',
        'input_min': '',
    });
    const [splph, setSplph] = useState({
        'label': '',
        'value': '',
        'factor': '',
        'input_device_name': '',
        'input_parameter': '',
        'input_max': '',
        'input_min': '',
    });

    // ERRORS HANDLER
    const [errors, setErrors] = useState({
        'sphph': '',
        'splph': '',
        'sphec': '',
        'splec': '',
    });

    const [deviceResponse, setDeviceResponse] = useState({});

    // USEEFFECT DISPLAY
    useEffect(() => {
        led_message = new steelseries.DisplaySingle('led-message', {
            width: 250,
            height: 40,
            value: message,
            valuesNumeric: false,
            autoScroll: true,
        });
        led_ec = new steelseries.DisplaySingle('led-ec', {
            width: 200,
            height: 50,
            unitString: ec.unit,
            unitStringVisible: true,
            headerString: ec.label,
            headerStringVisible: true,
            autoScroll: true,
        });
        led_ph = new steelseries.DisplaySingle('led-ph', {
            width: 200,
            height: 50,
            unitString: '',
            unitStringVisible: true,
            headerString: ph.label,
            headerStringVisible: true,
            autoScroll: true,
        });
        led_wt = new steelseries.DisplaySingle('led-wt', {
            width: 200,
            height: 50,
            unitString: wt.unit,
            unitStringVisible: true,
            headerString: wt.label,
            headerStringVisible: true,
            autoScroll: true,
        });

        for (let item of message_list) {
            if (item.number == deviceResponse['msg']) {
                led_message.setValue(item.text);
            }
        }
        led_ec.setValue(parseFloat(deviceResponse['ec']) / ec.factor);
        led_ph.setValue(parseFloat(deviceResponse['ph']) / ph.factor);
        led_wt.setValue(parseFloat(deviceResponse['wt']) / wt.factor);
    }, [deviceResponse]);

    // USEEFFECT MAIN
    useEffect(() => {
        setModalStatus(true);
        fetch(
            `${process.env.REACT_APP_API_URL}/getRemoteControlAgridose/${device_id}`,
            {
                headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
            }
        )
            .then((res) => res.json())
            .then(res => {
                console.log("*** res", res);
                message_list = res.msg;

                // OUTPUT
                for (let item of res.output) {
                    switch (item.parameter) {
                        // PURPLE
                        case 'ec':
                            setEc(prevState => ({
                                ...prevState,
                                'label': item.label,
                                'unit': item.unit,
                                'factor': parseInt(item.factor.replace('/', ''))
                            }));
                            break;
                        case 'ph':
                            setPh(prevState => ({
                                ...prevState,
                                'label': item.label,
                                'unit': item.unit,
                                'factor': parseInt(item.factor.replace('/', ''))
                            }));
                            break;
                        case 'wt':
                            setWt(prevState => ({
                                ...prevState,
                                'label': item.label,
                                'unit': item.unit,
                                'factor': parseInt(item.factor.replace('/', ''))
                            }));
                            break;
                        //SWITCH
                        case 'status':
                            // setStatus(prevState => ({
                            //     ...prevState,
                            //     'label': item.label,
                            // }))
                            break;
                        case 'psa':
                            setPsa(prevState => ({
                                ...prevState,
                                'label': item.label,
                            }));
                            break;
                        case 'psb':
                            setPsb(prevState => ({
                                ...prevState,
                                'label': item.label,
                            }));
                            break;
                        case 'psc':
                            setPsc(prevState => ({
                                ...prevState,
                                'label': item.label,
                            }));
                            break;
                        case 'osa':
                            setOsa(prevState => ({
                                ...prevState,
                                'label': item.label,
                            }));
                            break;
                        case 'addcl':
                            // setAddcl(prevState => ({
                            //     ...prevState,
                            //     'label': item.label,
                            // }))
                            break;
                        // TEXT INPUT
                        case 'sphec':
                            setSphec(prevState => ({
                                ...prevState,
                                'factor': parseInt(item.factor.replace('/', ''))
                            }));
                            sphec_factor = parseInt(item.factor.replace('/', ''));
                            break;
                        case 'splec':
                            setSplec(prevState => ({
                                ...prevState,
                                'factor': parseInt(item.factor.replace('/', ''))
                            }));
                            splec_factor = parseInt(item.factor.replace('/', ''));
                            break;
                        case 'sphph':
                            setSphph(prevState => ({
                                ...prevState,
                                'factor': parseInt(item.factor.replace('/', ''))
                            }));
                            sphph_factor = parseInt(item.factor.replace('/', ''));
                            break;
                        case 'splph':
                            setSplph(prevState => ({
                                ...prevState,
                                'factor': parseInt(item.factor.replace('/', ''))
                            }));
                            splph_factor = parseInt(item.factor.replace('/', ''));
                            break;

                        default:
                            break;
                    }
                }

                // INPUT
                for (let item of res.input) {
                    switch (item.parameter) {
                        //SWITCH
                        case 'status':
                            setStatus(prevState => ({
                                ...prevState,
                                'label': item['label'],
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter']
                            }));
                            break;
                        case 'psa':
                            setPsa(prevState => ({
                                ...prevState,
                                'label': item['label'],
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter']
                            }));
                            break;
                        case 'psb':
                            setPsb(prevState => ({
                                ...prevState,
                                'label': item['label'],
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter']
                            }));
                            break;
                        case 'psc':
                            setPsc(prevState => ({
                                ...prevState,
                                'label': item['label'],
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter']
                            }));
                            break;
                        case 'osa':
                            setOsa(prevState => ({
                                ...prevState,
                                'label': item['label'],
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter']
                            }));
                            break;
                        case 'addcl':
                            setAddcl(prevState => ({
                                ...prevState,
                                'label': item['label'],
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter']
                            }));
                            break;
                        // TEXT INPUT
                        case 'sphec':
                            setSphec(prevState => ({
                                ...prevState,
                                'label': item.label + " ( " + item.unit + " )",
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter'],
                                'input_max': item['device_max'] ? item['device_max'] : item['default_max'],
                                'input_min': item['device_min'] ? item['device_min'] : item['default_min'],
                            }));
                            break;
                        case 'splec':
                            setSplec(prevState => ({
                                ...prevState,
                                'label': item.label + " ( " + item.unit + " )",
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter'],
                                'input_max': item['device_max'] ? item['device_max'] : item['default_max'],
                                'input_min': item['device_min'] ? item['device_min'] : item['default_min'],
                            }));
                            break;
                        case 'sphph':
                            setSphph(prevState => ({
                                ...prevState,
                                'label': item.label,
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter'],
                                'input_max': item['device_max'] ? item['device_max'] : item['default_max'],
                                'input_min': item['device_min'] ? item['device_min'] : item['default_min'],
                            }));
                            break;
                        case 'splph':
                            setSplph(prevState => ({
                                ...prevState,
                                'label': item.label,
                                'input_device_name': item['device_name'],
                                'input_parameter': item['parameter'],
                                'input_max': item['device_max'] ? item['device_max'] : item['default_max'],
                                'input_min': item['device_min'] ? item['device_min'] : item['default_min'],
                            }));
                            break;


                        default:
                            break;
                    }
                }

                connectToDevice();
            }).catch(err => {
                errorToast(err);
                window.location.href = "/";
            });

    }, []);

    const connectToDevice = () => {
        console.log(`*** connecting to wss://${mqtt_server}:9001`);
        var client = mqtt.connect(`wss://${mqtt_server}:9001`, {
            username: mqtt_username,
            password: mqtt_password
        });


        let srrdPayload = `{
            "dev_name":"all",
            "var_name":"all" 
        }`;

        switch (telemetry) {
            case '1.0':
                srrdPayload = `{
                    "dev_name":"all",
                    "var_name":"all" 
                }`;
                break;
            case '3.0':
                srrdPayload = `{
                    "dev_name":"all",
                    "var_name":"all" 
                }`;
                break;
            case '5.0':
                srrdPayload = `{
                    "messageId": "${device_id}",
                    "devices": "all",
                    "mode": 0 
                }`;
                break;
        }

        client.on('connect', function () {
            console.log("*** connected.");
            setModalStatus(true);
            console.log(`*** subscribing to farm/srrp/${device_id}...`);
            client.subscribe('farm/srrp/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/srrp/${device_id}.`);
                    console.log(`*** publishing to farm/srrd/${device_id}...`);
                    client.publish('farm/srrd/' + device_id, srrdPayload);
                }
            });
        });

        client.on('message', function (topic, message) {
            const responseMessage = JSON.parse(message);
            console.log(`*** message received from topic: ${topic}.`);
            console.log(`*** responseMessage:`, responseMessage);

            client.end();

            let device_response = {};
            switch (telemetry) {
                case '1.0':
                    device_response = responseMessage.device[0]['variable'];
                    console.log("*** device_response", device_response);
                    break;
                case '3.0':
                    device_response = responseMessage.device[0]['variable'];
                    console.log("*** device_response", device_response);
                    break;
                case '5.0':
                    responseMessage.devices.forEach(d => {
                        const targetDeviceIds = ["monitoring", "control", "setpoint", "protect"];

                        if (targetDeviceIds.indexOf(d.deviceId) > -1) {
                            for (const param in d.deviceData) {
                                device_response[param] = `${d.deviceData[param]}`;
                            }
                        }
                    });
                    console.log("*** device_response", device_response);
                    break;
            }

            // Check for 'bad' status
            for (let i in device_response) {
                if (device_response[i] == 'bad' && connection_time < 3) {
                    setLoadingMessage('Reconnecting...');
                    connection_time++;
                    connectToDevice();
                    break;
                } else if (connection_time >= 3) {
                    errorToast('Failed to connect device. Please refresh again.');
                    setModalStatus(false);
                    break;
                }
            }
            connection_time = 0;
            setModalStatus(false);

            // SET DEVICE RESPONSE
            setDeviceResponse(device_response);

            // LED
            setEc(prevState => ({
                ...prevState,
                'value': device_response['ec']
            }));

            setPh(prevState => ({
                ...prevState,
                'value': device_response['ph']
            }));

            setWt(prevState => ({
                ...prevState,
                'value': device_response['wt']
            }));

            //SWITCH
            setStatus(prevState => ({
                ...prevState,
                'value': device_response['status']
            }));
            setPsa(prevState => ({
                ...prevState,
                'value': device_response['psa']
            }));
            setPsb(prevState => ({
                ...prevState,
                'value': device_response['psb']
            }));
            setPsc(prevState => ({
                ...prevState,
                'value': device_response['psc']
            }));
            setOsa(prevState => ({
                ...prevState,
                'value': device_response['osa']
            }));
            setAddcl(prevState => ({
                ...prevState,
                'value': device_response['addcl']
            }));

            // TEXT INPUT 
            setSphec(prevState => ({
                ...prevState,
                'value': device_response['sphec'] == 'bad' ? '' : device_response['sphec'] / sphec_factor
            }));
            setSplec(prevState => ({
                ...prevState,
                'value': device_response['splec'] == 'bad' ? '' : device_response['splec'] / splec_factor
            }));
            setSphph(prevState => ({
                ...prevState,
                'value': device_response['sphph'] == 'bad' ? '' : device_response['sphph'] / sphph_factor
            }));
            setSplph(prevState => ({
                ...prevState,
                'value': device_response['splph'] == 'bad' ? '' : device_response['splph'] / splph_factor
            }));

        });
    };

    const switchChange = (type) => {
        let not_value;
        switch (type) {
            case 'status':
                not_value = (status.value == 1 ? 0 : 1);
                switchMqtt(type, status.input_device_name, status.input_parameter, not_value);
                break;
            case 'psa':
                not_value = (psa.value == 1 ? 0 : 1);
                switchMqtt(type, psa.input_device_name, psa.input_parameter, not_value);
                break;
            case 'psb':
                not_value = (psb.value == 1 ? 0 : 1);
                switchMqtt(type, psb.input_device_name, psb.input_parameter, not_value);
                break;
            case 'psc':
                not_value = (psc.value == 1 ? 0 : 1);
                switchMqtt(type, psc.input_device_name, psc.input_parameter, not_value);
                break;
            case 'osa':
                not_value = (osa.value == 1 ? 0 : 1);
                switchMqtt(type, osa.input_device_name, osa.input_parameter, not_value);
                break;
            case 'addcl':
                not_value = (addcl.value == 1 ? 0 : 1);
                switchMqtt(type, addcl.input_device_name, addcl.input_parameter, not_value);
                break;
            default:
                break;
        }
    };

    const switchMqtt = async (type, device_name, parameter, value) => {
        console.log("*** switchMqtt device_name", device_name);
        console.log("*** switchMqtt parameter", parameter);
        console.log("*** switchMqtt value", value);
        setModalStatus(true);
        setLoadingMessage('Uploading');

        // let response = await recheckInputFromDevice(device_name, parameter, value);

        // if (response == 'Success') {
        var client_1 = mqtt.connect(`wss://${mqtt_server}:9001`, {
            username: mqtt_username,
            password: mqtt_password
        });

        let aswrPayload = `{
            "${device_name}": {
                "${parameter}" : "${value}"
            }
        }`;

        switch (telemetry) {
            case '1.0':
                aswrPayload = `{
                    "${device_name}": { 
                        "${parameter}" : "${value}"
                    }
                }`;
                break;
            case '3.0':
                aswrPayload = `{
                    "${device_name}": { 
                        "${parameter}" : "${value}"
                    }
                }`;
                break;
            case '5.0':
                aswrPayload = `{
                "messageId" : "${device_id}",
                "devices" : [{
                    "deviceId" : "${device_name}",
                    "deviceData": {
                        "${parameter}" : ${value}
                      }
                    }]
                }`;
                break;
        }

        client_1.on('connect', function () {
            console.log(`*** subscribing to farm/aswa/${device_id}`);
            client_1.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}`);
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload);
                    client_1.publish('farm/aswr/' + device_id, aswrPayload);
                }
            });
        });

        client_1.on('message', function (topic, message) {
            console.log("*** received from switchMqtt topic: ", topic);
            console.log("*** received switchMqtt message", message.toString());
            // message is Buffer
            client_1.end();

            const message_json = JSON.parse(message.toString());

            if (message_json.result && message_json.result === "error") {
                errorToast(`[ERROR] Please resubmit.`);
            } else {
                successToast("[SUCCESS] Value updated.");
            }

            setLoadingMessage('Connecting');
            connectToDevice();
        });

        // SET BACK VALUE
        switch (type) {
            case 'status':
                setStatus(prevState => ({
                    ...prevState,
                    'value': value
                }));
                break;
            case 'psa':
                setPsa(prevState => ({
                    ...prevState,
                    'value': value
                }));
                break;
            case 'psb':
                setPsb(prevState => ({
                    ...prevState,
                    'value': value
                }));
                break;
            case 'psc':
                setPsc(prevState => ({
                    ...prevState,
                    'value': value
                }));
                break;
            case 'osa':
                setOsa(prevState => ({
                    ...prevState,
                    'value': value
                }));
                break;
            case 'addcl':
                setAddcl(prevState => ({
                    ...prevState,
                    'value': value
                }));
                break;
            default:
                break;

        }
        // } else {
        //     alert("Value has been changed at the device");
        //     setLoadingMessage('Connecting');
        //     connectToDevice();
        // }

    };

    const textChange = (e, type) => {
        if (!/^\d*\.?\d*$/i.test(e.target.value)) {
            return null;
        }
        switch (type) {
            case 'sphec':
                setSphec(prevState => ({
                    ...prevState,
                    'value': e.target.value
                }));
                break;
            case 'splec':
                setSplec(prevState => ({
                    ...prevState,
                    'value': e.target.value
                }));
                break;
            case 'sphph':
                setSphph(prevState => ({
                    ...prevState,
                    'value': e.target.value
                }));
                break;
            case 'splph':
                setSplph(prevState => ({
                    ...prevState,
                    'value': e.target.value
                }));
                break;
            default:
                break;
        }
    };

    // INPUT VALIDATION USEEFFECT
    useEffect(() => {
        if (parseFloat(sphph.value) < parseFloat(sphph.input_min) || parseFloat(sphph.value) > parseFloat(sphph.input_max)) {
            setErrors(prevState => ({
                ...prevState,
                'sphph': `Minimum ${sphph.input_min}, Maximum ${sphph.input_max}`
            }));
        } else {
            setErrors(prevState => ({
                ...prevState,
                'sphph': ''
            }));
        }

        if (parseFloat(splph.value) < parseFloat(splph.input_min) || parseFloat(splph.value) > parseFloat(splph.input_max)) {
            setErrors(prevState => ({
                ...prevState,
                'splph': `Minimum ${splph.input_min}, Maximum ${splph.input_max}`
            }));
        } else {
            setErrors(prevState => ({
                ...prevState,
                'splph': ''
            }));
        }


        if (parseFloat(sphec.value) > 4) {
            setErrors(prevState => ({
                ...prevState,
                'sphec': `Maximum input 4`
            }));
        } else if (parseFloat(sphec.value) < parseFloat(sphec.input_min) || parseFloat(sphec.value) > parseFloat(sphec.input_max)) {
            setErrors(prevState => ({
                ...prevState,
                'sphec': `Minimum ${sphec.input_min}, Maximum ${sphec.input_max}`
            }));
        } else if (sphec.value < splec.value) {
            setErrors(prevState => ({
                ...prevState,
                'sphec': 'Water EC High must be larger than Water EC Low'
            }));
        } else {
            setErrors(prevState => ({
                ...prevState,
                'sphec': ''
            }));
        }


        if (parseFloat(splec.value) < parseFloat(splec.input_min) || parseFloat(splec.value) > parseFloat(splec.input_max)) {
            setErrors(prevState => ({
                ...prevState,
                'splec': `Minimum ${splec.input_min}, Maximum ${splec.input_max}`
            }));
        } else if (splec.value > sphec.value) {
            setErrors(prevState => ({
                ...prevState,
                'splec': 'Water EC Low must be smaller than Water EC High'
            }));
        } else {
            setErrors(prevState => ({
                ...prevState,
                'splec': ''
            }));
        }
    }, [sphph, splph, sphec, splec]);

    const recheckInputFromDevice = (dev_name, var_name, value) => {
        var client = mqtt.connect(`wss://${mqtt_server}:9001`, {
            username: mqtt_username,
            password: mqtt_password
        });

        return new Promise((resolve, reject) => {
            client.on('connect', function () {
                client.subscribe('farm/srrp/' + device_id, function (err) {
                    if (!err) {
                        client.publish('farm/srrd/' + device_id,
                            `{ "dev_name":"${dev_name}",
                        "var_name":"${var_name}" }`);
                    }
                });
            });

            client.on('message', function (topic, message) {
                // message is Buffer
                client.end();

                if (JSON.parse(message.toString())[var_name] == 'bad' || JSON.parse(message.toString())[var_name] == 'fail') {
                    // RETRY CONNECTION
                    recheckInputFromDevice(dev_name, var_name, value);
                } else {
                    if (value == JSON.parse(message.toString())[var_name]) {
                        resolve('Value has been changed at the device');
                    } else {
                        resolve('Success');
                    }
                }
            });
        });

    };

    const setInput = async (type, device_name, parameter, value, factor) => {
        console.log("*** setInput");
        setModalStatus(true);
        setLoadingMessage('Uploading');
        let real_value = value * factor;

        // let response = await recheckInputFromDevice(device_name, parameter, real_value);

        // if (response == 'Success') {
        var client_1 = mqtt.connect(`wss://${mqtt_server}:9001`, {
            username: mqtt_username,
            password: mqtt_password
        });

        let aswrPayload = `{
            "${device_name}": {
                "${parameter}" : "${real_value}"
            }
        }`;

        switch (telemetry) {
            case '1.0':
                aswrPayload = `{
                    "${device_name}": { 
                        "${parameter}" : "${real_value}"
                    }
                }`;
                break;
            case '3.0':
                aswrPayload = `{
                    "${device_name}": { 
                        "${parameter}" : "${real_value}"
                    }
                }`;
                break;
            case '5.0':
                aswrPayload = `{
                "messageId" : "${device_id}",
                "devices" : [{
                    "deviceId" : "${device_name}",
                    "deviceData": {
                        "${parameter}" : ${Math.round(real_value)}
                      }
                    }]
                }`;
                break;
        }

        client_1.on('connect', function () {
            console.log(`*** subscribing to 'farm/aswa/${device_id}'...`);
            client_1.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}.`);
                    console.log(`*** publishing to farm/aswr/${device_id}:`, aswrPayload);
                    client_1.publish('farm/aswr/' + device_id, aswrPayload);
                }
            });
        });

        client_1.on('message', function (topic, message) {
            console.log("*** received from setInput topic:", topic);
            console.log("*** received setInput message:", message.toString());
            // message is Buffer
            client_1.end();

            const message_json = JSON.parse(message.toString());
            if (message_json.result && message_json.result === "error") {
                errorToast(`[ERROR] Please resubmit.`);
            } else {
                successToast("[SUCCESS] Value updated.");
            }

            setLoadingMessage('Connecting');
            connectToDevice();
        });
        // } else {
        //     alert("Value has been changed at the device");
        //     setLoadingMessage('Connecting');
        //     connectToDevice();
        // }
    };

    return (
        <>
            <div className="modal" style={{
                display: (modalStatus ? 'block' : 'none'),
                position: 'fixed',
                zIndex: 1000,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                background: "rgba( 255, 255, 255, .8 ) url('https://i.stack.imgur.com/FhHRx.gif') 50% 50% no-repeat",
            }}>
                <p style={{ position: 'absolute', right: '45%', top: '55%' }}>{loadingMessage}</p>
            </div>
            <Grid container spacing={2} style={{
                textAlign: 'center', maxWidth: '750px',
                margin: 'auto',
            }}>
                <Grid container style={{ marginTop: '5px', marginBottom: '2px' }}>
                    <Grid item xs={2}>
                        <Button  variant="contained" color="primary" aria-label="back" onClick={() => { return window.location.href = "/#/v2/dashboard"; }}>
                            <ArrowBackRoundedIcon/>
                        </Button>
                    </Grid>
                    <Grid item xs={8}><canvas id="led-message"></canvas></Grid>
                    <Grid item xs={1}>
                        <Button  variant="contained"  color="primary" onClick={() => { return window.location.reload(); }} >
                            <RefreshIcon/>
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}><canvas id="led-ec" ></canvas></Grid>
                    <Grid item xs={12} sm={6} md={4}><canvas id="led-ph" width="120" height="50"></canvas></Grid>
                    <Grid item xs={12} sm={12} md={4}><canvas id="led-wt" width="120" height="50"></canvas></Grid>
                </Grid>

                <Grid item xs={12} style={{marginTop:'30px'}}>
                    <div style={{ marginBottom: '20px' }}>
                        <TextField
                            error={errors.sphec == '' ? false : true}
                            helperText={errors.sphec}
                            value={sphec.value ? sphec.value : ''} style={{ width: '70%' }} label={sphec.label == null ? 'NULL' : sphec.label} onChange={(e) => { textChange(e, 'sphec'); }} />
                        <Button disabled={errors.sphec == '' ? false : true} variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { setInput('sphec', sphec.input_device_name, sphec.input_parameter, sphec.value, sphec.factor); }}> SET </Button>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <TextField
                            error={errors.splec == '' ? false : true}
                            helperText={errors.splec}
                            value={splec.value ? splec.value : ''} style={{ width: '70%' }} label={splec.label == null ? 'NULL' : splec.label} onChange={(e) => { textChange(e, 'splec'); }} />
                        <Button disabled={errors.splec == '' ? false : true} variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { setInput('splec', splec.input_device_name, splec.input_parameter, splec.value, splec.factor); }}> SET </Button>
                    </div>

                </Grid>

                <Grid container style={{ border: '1px solid #ccc', padding: '0.2em 16px', borderRadius: '16px', marginBottom: '5px' }}>
                    <Grid item xs={12}>
                        <span>{addcl.label}</span>
                        <Switch
                            checked={addcl.value == 1 ? true : false}
                            disabled={addcl.value == 'bad' ? true : false}
                            onChange={() => switchChange('addcl')}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
                        <TextField
                            error={errors.sphph == '' ? false : true}
                            helperText={errors.sphph}
                            value={sphph.value ? sphph.value : ''}
                            style={{ width: '70%' }}
                            label={sphph.label == null ? 'NULL' : sphph.label}
                            onChange={(e) => { textChange(e, 'sphph'); }} />
                        <Button disabled={errors.sphph == '' ? false : true} variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { setInput('sphph', sphph.input_device_name, sphph.input_parameter, sphph.value, sphph.factor); }}> SET </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
                        <TextField
                            error={errors.splph == '' ? false : true}
                            helperText={errors.splph}
                            value={splph.value ? splph.value : ''}
                            style={{ width: '70%' }}
                            label={splph.label == null ? 'NULL' : splph.label}
                            onChange={(e) => { textChange(e, 'splph'); }} />
                        <Button disabled={errors.splph == '' ? false : true} variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { setInput('splph', splph.input_device_name, splph.input_parameter, splph.value, splph.factor); }}> SET </Button>
                    </Grid>
                </Grid>

                <div
                style={{border:'1px solid #ccc', borderRadius: 10, width:'100%', marginTop:15}}>
                    <Grid item xs={12} center>
                        {status.label == null ? 'NULL' : status.label}
                        <Switch
                            checked={status.value == 0 ? true : false}
                            disabled={status.value == 'bad' ? true : false}
                            onChange={() => switchChange('status')}
                            color="primary"
                        />
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <span>{psa.label}</span>
                            <Switch
                                checked={psa.value == 1 ? true : false}
                                disabled={psa.value == 'bad' ? true : false}
                                onChange={() => switchChange('psa')}
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <span>{psb.label}</span>
                            <Switch
                                checked={psb.value == 1 ? true : false}
                                disabled={psb.value == 'bad' ? true : false}
                                onChange={() => switchChange('psb')}
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <span>{psc.label}</span>
                            <Switch
                                checked={psc.value == 1 ? true : false}
                                disabled={psc.value == 'bad' ? true : false}
                                onChange={() => switchChange('psc')}
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <span>{osa.label}</span>
                            <Switch
                                checked={osa.value == 1 ? true : false}
                                disabled={osa.value == 'bad' ? true : false}
                                onChange={() => switchChange('osa')}
                                color="primary"
                            />
                        </Grid>

                    </Grid>
                </div>


            </Grid>
        </>
    );
}
