import { useState, useRef } from 'react';

const useTimer = (initialState = 0) => {
  const [timer, setTimer] = useState(initialState);
  const countRef = useRef(null);

  const handleTimerStart = () => {
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleTimerReset = () => {
    clearInterval(countRef.current);
    setTimer(0);
  };

  return { timer, handleTimerStart, handleTimerReset };
};

export default useTimer;