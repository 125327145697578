import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import category from '../../services/node-category/category';
import { successToast } from '../../shared/notification';

const AddCategoryModal = ({
    addCategoryModal,
    setAddCategoryModal,
    setLoading
}) => {
    const [newCatName, setNewCatName] = useState('')

	function onAddCategory(e) {
		e.preventDefault();

		let dataToSubmit = {
			category:  newCatName,
		};
		setLoading(true);
		category
			.getInstance()
			.addCategory(dataToSubmit)
			.then((result) => {
				// setCategoryList((categoryList) => [
				// 	...categoryList,
				// 	{ category: newCatName },
				// ]);
				successToast(result["message"])
				setLoading(false)
                setTimeout(()=>{
				    window.location.reload()
                }, 1500)
			});
	}
    return (
        
        <Modal isOpen={addCategoryModal}  className={"sm"} size="lg">
            <form onSubmit={onAddCategory}>
                <ModalHeader onClick={()=>{setAddCategoryModal(false)}}>Add Category</ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label for="txt_category">Category Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="txt_category"
                                        value={newCatName}
                                        onChange={(e) => setNewCatName(e.target.value)}
                                        required
                                    />
                                    <small id="txt_category" className="form-text text-muted">
                                        Proper Category.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-info">
                        Add Category
                    </button>
                    <Button color="secondary" onClick={()=>{setAddCategoryModal(false)}}>
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default AddCategoryModal