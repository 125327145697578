import { Button, IconButton, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { steelseries } from '../Assets/SteelSeries/steelseries';
import { errorToast, successToast } from '../shared/notification';
import axios from 'axios';

let led_ec, led_ph, led_wt, led_message;

const ControllerPoll = () => {const [modalStatus, setModalStatus] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const { device_id, serial } = useParams();

    const [controllerData, setControllerData] = useState({})

    // USEEFFECT DISPLAY
    useEffect(() => {
        led_message = new steelseries.DisplaySingle('led-message', {
            width: 250,
            height: 40,
            value: controllerData.msg,
            valuesNumeric: false,
            autoScroll: true,
        });
        led_ec = new steelseries.DisplaySingle('led-ec', {
            width: 200,
            height: 50,
            unitString: "mS/cm",
            unitStringVisible: true,
            headerString: "Water EC",
            headerStringVisible: true,
            autoScroll: true,
        });
        led_ph = new steelseries.DisplaySingle('led-ph', {
            width: 200,
            height: 50,
            unitString: "",
            unitStringVisible: true,
            headerString: "Water pH",
            headerStringVisible: true,
            autoScroll: true,
        });
        led_wt = new steelseries.DisplaySingle('led-wt', {
            width: 200,
            height: 50,
            unitString: "C",
            unitStringVisible: true,
            headerString: "Water Temperature",
            headerStringVisible: true,
            autoScroll: true,
        });

        led_ec.setValue(controllerData.ec || 0);
        led_ph.setValue(controllerData.ph || 0);
        led_wt.setValue(controllerData.wt || 0);
    }, [controllerData]);

    const [telemetryId, setTelemetryId] = useState(null)
    // USEEFFECT MAIN
    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setModalStatus(true);
        setLoadingMessage("Trying to read data from controller")
        console.log("device_id", serial)
        // https://api.cloudatik.com/telemetry/v1.0/publish/
        axios.post(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/publish/`, {
            "serial": serial,
            "service":"agridose_control",
            "command":"read_data",
            "param": {"value":"all"}
        }, {headers: { 'Authorization': localStorage.getItem('cloudatik-access') },})
        .then(res => {
            setModalStatus(false);
            // console.log("*** res", res);
            if (!res.data["success"]) return alert(res.data["message"])
            setTelemetryId(res.data["telemetry id"])
        }).catch(err => {
            errorToast(err);
            setModalStatus(false);
            // window.location.href = "/";
        });
    }

    function clearAllInterval() {
        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }
    }

    useEffect(() => {

        if (telemetryId) {
            let count = 0;
            setModalStatus(true)
            const fetchData = async () => {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/status/${telemetryId}`, {
                    headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
                })
                
                // console.log("response.data", response.data)
        
                if (!response.data.return && count >= 20) {
                    setModalStatus(false)
                    clearAllInterval()
                    return alert("Server error")
                } else if (response.data.return && response.data.message == "Value Has Been Set!") {
                    setModalStatus(false)
                    clearAllInterval()
                    getData()
                }  else if (response.data.return && response.data.message == "Data Received!") {
                    setModalStatus(false)
                    clearAllInterval()
                    // console.log(response.data.payload.replace(/'/g, '"'))
                    setControllerData(JSON.parse(response.data.payload.replace(/'/g, '"')))
                    // {'ec': 0.88, 'ph': 6.8, 'wt': 29.9, 'msg': 'EC Protection Timeout Activated', 'mode': 0, 'pump_a': 0.0, 'pump_b': 0.0, 'pump_c': 0.0, 'buffer': 0.0, 'ec_setpoint': 1.0, 'ec_delta': 0.0, 'ph_setpoint': 12.0, 'ph_delta': 0.2}
                }
            }
        
            // Initial call
            fetchData();
            // Set up interval for subsequent calls every 3 seconds
            const intervalId = setInterval(() => {
                count += 3;
                fetchData();
            }, 3000);
            // Cleanup function
            return () => {
                clearInterval(intervalId);
            };
        
        }
    }, [telemetryId]);
    
    

    const switchChange = (e, field) => {
        let switchNumeric = e.target.checked ? 1 : 0
        setControllerData(prev=> ({
            ...prev,
            [field]: switchNumeric
        }))
        // console.log({ [field] : switchNumeric })
        setLoadingMessage("Sending data to controller")
        axios.post(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/publish/`, {
            "serial": serial,
            "service":"agridose_control",
            "command":"set_value",
            "param": { [field] : switchNumeric }
        }, {headers: { 'Authorization': localStorage.getItem('cloudatik-access') },})
        .then(res => {
            setModalStatus(false)
            if (!res.data["success"]) return alert(res.data["message"])
            // console.log("*** res", res)
            alert("Sent. Please refresh and check if setting is updated.")
            // setTelemetryId(res.data["telemetry id"])
        }).catch(err => {
            console.log(err)
        });
    }

    const [inputError, setInputError] = useState({
        "ec_setpoint": {
            status: false,
            message: ""
        },
        "ec_delta": {
            status: false,
            message: ""
        },
        "ph_setpoint": {
            status: false,
            message: ""
        },
        "ph_delta": {
            status: false,
            message: ""
        },
    })

    const textChange = (e, field) => {
        if (!/^\d*\.?\d*$/i.test(e.target.value)) return null
        // ec
        if (field == "ec_setpoint" && (e.target.value < 0.5 || e.target.value > 3)) {
            setInputError( prev => ({
                ...prev,
                "ec_setpoint": {
                    status:true,
                    message: "Minimum 0.5, Maximum 3"
                }
            }))
        } else if (field == "ec_setpoint" && e.target.value < controllerData.ec_delta) {
            setInputError( prev => ({
                ...prev,
                "ec_setpoint": {
                    status:true,
                    message: "Water EC High must be larger than Water EC Low"
                }
            }))
        } else if (field == "ec_setpoint") {
            setInputError( prev => ({
                ...prev,
                "ec_setpoint": {
                    status:false,
                    message: ""
                }
            }))
        }
        
        if (field == "ec_delta" && (e.target.value < 0.1 || e.target.value > 1)) {
            setInputError( prev => ({
                ...prev,
                "ec_delta": {
                    status:true,
                    message: "Minimum 0.1, Maximum 1"
                }
            }))
        } else if (field == "ec_delta" && e.target.value > controllerData.ec_setpoint) {
            setInputError( prev => ({
                ...prev,
                "ec_delta": {
                    status:true,
                    message: "Water EC Low must be smaller than Water EC High"
                }
            }))
        } else if (field == "ec_delta") {
            setInputError( prev => ({
                ...prev,
                "ec_delta": {
                    status:false,
                    message: ""
                },
            }))
        }

        // pH
        if (field == "ph_setpoint" && ((e.target.value < 1 || e.target.value > 14) || e.target.value == "")) {
            setInputError( prev => ({
                ...prev,
                "ph_setpoint": {
                    status:true,
                    message: "Minimum 1, Maximum 14"
                }
            }))
        } else if (field == "ph_setpoint") {
            setInputError( prev => ({
                ...prev,
                "ph_setpoint": {
                    status:false,
                    message: ""
                },
            }))
        }
        
        if (field == "ph_delta" && ((e.target.value < 0.1 || e.target.value > 1) || e.target.value == "")) {
            setInputError( prev => ({
                ...prev,
                "ph_delta": {
                    status:true,
                    message: "Minimum 0.1, Maximum 1"
                }
            }))
        } else if (field == "ph_delta") {
            setInputError( prev => ({
                ...prev,
                "ph_delta": {
                    status:false,
                    message: ""
                },
            }))
        }

        setControllerData(prev=> ({
            ...prev,
            [field]: e.target.value
        }))
    }
    

    function sendSetting(field) {
        // console.log({ [field] : controllerData[field] })

        setModalStatus(true)
        setLoadingMessage("Sending data to controller")
        axios.post(`${process.env.REACT_APP_API_URL}/telemetry/v1.0/publish/`, {
            "serial": serial,
            "service":"agridose_control",
            "command":"set_value",
            "param": { [field] : controllerData[field] }
        }, {headers: { 'Authorization': localStorage.getItem('cloudatik-access') },})
        .then(res => {
            setModalStatus(false)
            if (!res.data["success"]) return alert(res.data["message"])

            alert("Sent. Please refresh and check if setting is updated.")
            // console.log("*** res", res)
            // setTelemetryId(res.data["telemetry id"])
        }).catch(err => {
            console.log(err)
            // window.location.href = "/";
        });
    }

    return (
        <>
            <div className="modal" style={{
                display: (modalStatus ? 'block' : 'none'),
                position: 'fixed',
                zIndex: 1000,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                background: "rgba( 255, 255, 255, .8 ) url('https://i.stack.imgur.com/FhHRx.gif') 50% 50% no-repeat",
            }}>
                <p style={{ position: 'absolute', right: '45%', top: '55%' }}>{loadingMessage}</p>
            </div>
            <Grid container spacing={2} style={{
                textAlign: 'center', maxWidth: '750px',
                margin: 'auto',
            }}>
                <Grid container style={{ marginTop: '5px', marginBottom: '2px' }}>
                    <Grid item xs={2}>
                        <Button  variant="contained" color="primary" aria-label="back" onClick={() => { return window.location.href = "/#/v2/dashboard"; }}>
                            <ArrowBackRoundedIcon/>
                        </Button>
                    </Grid>
                    <Grid item xs={8}><canvas id="led-message"></canvas></Grid>
                    <Grid item xs={1}>
                        <Button  variant="contained"  color="primary" onClick={() => { return window.location.reload(); }} >
                            <RefreshIcon/>
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}><canvas id="led-ec" ></canvas></Grid>
                    <Grid item xs={12} sm={6} md={4}><canvas id="led-ph" width="120" height="50"></canvas></Grid>
                    <Grid item xs={12} sm={12} md={4}><canvas id="led-wt" width="120" height="50"></canvas></Grid>
                </Grid>

                <Grid item xs={12} style={{marginTop:'30px'}}>
                    <div style={{ marginBottom: '20px' }}>
                        <TextField
                            value={controllerData.ec_setpoint || ''}
                            style={{ width: '70%' }}
                            label={"EC High Set Point (mS/cm)"}
                            error={inputError.ec_setpoint.status}
                            helperText={inputError.ec_setpoint.message}
                            onChange={(e) => { textChange(e, 'ec_setpoint'); }} />
                        <Button 
                        disabled={inputError.ec_setpoint.status} 
                        variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { sendSetting('ec_setpoint') }}> SET </Button>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <TextField
                            value={controllerData.ec_delta || ''}
                            style={{ width: '70%' }}
                            label={"EC Delta Set Point (mS/cm)"}
                            error={inputError.ec_delta.status}
                            helperText={inputError.ec_delta.message}
                            onChange={(e) => { textChange(e, 'ec_delta'); }} />
                        <Button 
                        disabled={inputError.ec_delta.status} 
                        variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { sendSetting('ec_delta') }}> SET </Button>
                    </div>

                </Grid>

                <Grid container style={{ border: '1px solid #ccc', padding: '0.2em 16px', borderRadius: '16px', marginBottom: '5px' }}>
                    <Grid item xs={12}>
                        <span>Add Acid/Alkali</span>
                        <Switch
                            checked={controllerData.buffer ? true : false}
                            // disabled={addcl.value == 'bad' ? true : false}
                            onChange={(e) => switchChange(e, 'buffer')}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
                        <TextField
                            // error={errors.sphph == '' ? false : true}
                            // helperText={errors.sphph}
                            value={controllerData.ph_setpoint || ''}
                            style={{ width: '70%' }}
                            label={"ph High Set Point"}
                            error={inputError.ph_setpoint.status}
                            helperText={inputError.ph_setpoint.message}
                            onChange={(e) => { textChange(e, 'ph_setpoint'); }} />
                        <Button
                        disabled={inputError.ph_setpoint.status}
                        variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { sendSetting('ph_setpoint') }}> SET </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
                        <TextField
                            // error={errors.splph == '' ? false : true}
                            // helperText={errors.splph}
                            value={controllerData.ph_delta || ''}
                            style={{ width: '70%' }}
                            label={"delta pH Set Point"}
                            error={inputError.ph_delta.status}
                            helperText={inputError.ph_delta.message}
                            onChange={(e) => { textChange(e, 'ph_delta'); }} />
                        <Button 
                        disabled={inputError.ph_delta.status}
                         variant="contained" color="primary" style={{ float: 'right', width: '20%' }} onClick={() => { sendSetting('ph_delta') }}> SET </Button>
                    </Grid>
                </Grid>

                <div
                style={{border:'1px solid #ccc', borderRadius: 10, width:'100%', marginTop:15}}>
                    <Grid item xs={12}>
                        Edit/Run Mode
                        <Switch
                            checked={controllerData.mode ? true : false}
                            // disabled={status.value == 'bad' ? true : false}
                            onChange={(e) => switchChange(e, 'mode')}
                            color="primary"
                        />
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <span>Pump A Off/On</span>
                            <Switch
                                checked={controllerData.pump_a ? true : false}
                                // disabled={psa.value == 'bad' ? true : false}
                                onChange={(e) => switchChange(e, 'pump_a')}
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <span>Pump B Off/On</span>
                            <Switch
                                checked={controllerData.pump_b ? true : false}
                                // disabled={psb.value == 'bad' ? true : false}
                                onChange={(e) => switchChange(e,'pump_b')}
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <span>pH Pump Off/On</span>
                            <Switch
                                checked={controllerData.pump_c ? true : false}
                                // disabled={psc.value == 'bad' ? true : false}
                                onChange={(e) => switchChange(e, 'pump_c')}
                                color="primary"
                            />
                        </Grid>

                    </Grid>
                </div>


            </Grid>
        </>
    );
}

export default ControllerPoll