/**
 *
 * @param arr Array JSON List
 * @param prop JSON Key to Sort
 * @returns
 */
export function getMax(arr, prop) {
	var maxValue = Number.MIN_VALUE;
	for (var i = 0; i < arr.length; i++) {
		if (parseFloat(arr[i][prop]) > parseFloat(maxValue)) {
			maxValue = arr[i][prop];
		}
	}
	return parseFloat(maxValue);
}

/**
 *
 * @param arr Array JSON List
 * @param prop JSON Key to Sort
 * @returns
 */
export function getMin(arr, prop) {
	var minValue = Number.MAX_VALUE;
	for (var i = 0; i < arr.length; i++) {
		if (parseFloat(arr[i][prop]) < parseFloat(minValue)) {
			minValue = arr[i][prop];
		}
	}
	return parseFloat(minValue);
}
