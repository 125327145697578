import React, { useState } from "react";
import AlarmHistory from "./AlarmHistory";
import EditAlarm from "./EditAlarm";
import NodeAlarm from "../../NodeAlarm";
import NodeProps from "../../NodeProps/index";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		// flexGrow: 1,
		// backgroundColor: theme.palette.background.paper,
	},
}));

function MainModal({ device_id, serial }) {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<div className={classes.root}>
				<AppBar position="static" style={{ backgroundColor: "white" }}>
					<center>
						<h3 style={{ color: "black" }}>{serial}</h3>
					</center>
					<Tabs
						value={value}
						onChange={handleChange}
						style={{ color: "black" }}
					>
						<Tab label="Edit Alarm" {...a11yProps(0)} />
						<Tab label="Alarm History" {...a11yProps(1)} />
					</Tabs>
				</AppBar>
				<TabPanel value={value} index={0}>
					<NodeProps device_id={device_id} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<AlarmHistory device_id={device_id} />
				</TabPanel>
			</div>
		</>
	);
}

export default MainModal;
