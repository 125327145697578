import { Card, CardContent, Grid } from '@material-ui/core'
import { Button, TextField, InputLabel, Modal, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Select, MenuItem, FormControl } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { errorToast, successToast } from "../../../../shared/notification";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from '../../../../shared/Spinner/LoadingSpinner';

const AR00XX3TBBlockSettingDb = ({
    blockSettings,
    currentSessionIndex, setCurrentSessionIndex,
    currentBlockIndex, setCurrentBlockIndex,
    setBlockSettings,
    uploadController,
    sessionSettings,
    setSessionSettings,
    showSessionForm
}) => {
    
    const [showAllSession, setShowAllSession] = useState(false)

    // function handleInputChange(e, field, key=null) {
    //     if (key) {
    //         // Show All session input change
    //         setBlockSettings(prev=> ({
    //             ...prev,
    //             [currentBlockIndex] : {
    //                 ...prev[currentBlockIndex],
    //                 [key]: {
    //                     ...prev[currentBlockIndex][key],
    //                     [field]:  parseFloat(e.target.value)
    //                 }
    //             }
    //         }))
    //     } else {
    //         setBlockSettings(prev=> ({
    //             ...prev,
    //             [currentBlockIndex] : {
    //                 ...prev[currentBlockIndex],
    //                 [currentSessionIndex]: {
    //                     ...prev[currentBlockIndex][currentSessionIndex],
    //                     [field]:  parseFloat(e.target.value)
    //                 }
    //             }
    //         }))
    //     }
    // }

    function resetSwitch(session) {
        setSessionSettings( prev => ({
            ...prev,
            [session]: {
                ...prev[session],
                "io_sc": 0
            }
        }))
    }
    
    function calculateEndTime(session, currentInputValue=null){
        // console.log("1calculate",session)
        if (session == "a" ||session == "b" ||session == "c" ) {
            let duration = 0
            for (let i in blockSettings) {
                if (blockSettings[i]["blkdisable"] == 0 && blockSettings[i][session]["io_sc"] == 1) {
                    duration += parseInt( blockSettings[i][session]["duration"] )
                }
            }
            let hours = parseInt(sessionSettings[session]["sc_h"]) * 60
            duration += hours
            duration += parseInt(sessionSettings[session]["sc_m"])

            // get the current value with input change 
            duration = duration - parseInt( blockSettings[currentBlockIndex][session]["duration"] ) + parseInt(currentInputValue)

            return convertMinsToHrsMins(duration)
        }
        return "00:00"
    }

    
    function calculateEndTimeSwitch(session, switchValue){
        // console.log("1calculate",session)
        if (session == "a" ||session == "b" ||session == "c" ) {
            let duration = 0
            for (let i in blockSettings) {
                if (blockSettings[i]["blkdisable"] == 0 && blockSettings[i][session]["io_sc"] == 1) {
                    duration += parseInt( blockSettings[i][session]["duration"] )
                }
            }
            let hours = parseInt(sessionSettings[session]["sc_h"]) * 60
            duration += hours
            duration += parseInt(sessionSettings[session]["sc_m"])

            //get current duration of triggered switch
            duration += parseInt(blockSettings[currentBlockIndex][session]["duration"])

            return convertMinsToHrsMins(duration)
        }
        return "00:00"
    }

    function handleInputChange(e, field, key=null) {
        // console.log(currentBlockIndex, currentSessionIndex, field, key)
        // console.log(e.target.value, blockSettings, blockSettings[currentBlockIndex][currentSessionIndex][field])

        if (key) {
            
            // // switch off current io_sc
            // setBlockSettings(prev=> ({
            //     ...prev,
            //     [currentBlockIndex] : {
            //         ...prev[currentBlockIndex],
            //         [key]: {
            //             ...prev[currentBlockIndex][key],
            //             "io_sc":  0
            //         }
            //     }
            // }))
            
            if (key == "a") {
                let end_time = calculateEndTime(key, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["b"]['sc_h'].toString() + minutePadding(sessionSettings["b"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['b', 'c', 'd', 'e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (key == "b") {
                let end_time = calculateEndTime(key, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["c"]['sc_h'].toString() + minutePadding(sessionSettings["c"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['c', 'd', 'e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (key == "c") {
                let end_time = calculateEndTime(key, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["d"]['sc_h'].toString() + minutePadding(sessionSettings["d"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['d', 'e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (key == "d") {
                let end_time = calculateEndTime(key, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["e"]['sc_h'].toString() + minutePadding(sessionSettings["e"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (key == "e") {
                let end_time = calculateEndTime(key, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["f"]['sc_h'].toString() + minutePadding(sessionSettings["f"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (key == "f") {
                let end_time = calculateEndTime(key, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            }

            // Show All session input change
            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [key]: {
                        ...prev[currentBlockIndex][key],
                        [field]:  parseFloat(e.target.value)
                    }
                }
            }))
        } else {
            // // switch off current io_sc
            // setBlockSettings(prev=> ({
            //     ...prev,
            //     [currentBlockIndex] : {
            //         ...prev[currentBlockIndex],
            //         [currentSessionIndex]: {
            //             ...prev[currentBlockIndex][currentSessionIndex],
            //             "io_sc":  0
            //         }
            //     }
            // }))
            
            if (currentSessionIndex == "a") {
                let end_time = calculateEndTime(currentSessionIndex, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["b"]['sc_h'].toString() + minutePadding(sessionSettings["b"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['b', 'c', 'd', 'e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (currentSessionIndex == "b") {
                let end_time = calculateEndTime(currentSessionIndex, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["c"]['sc_h'].toString() + minutePadding(sessionSettings["c"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['c', 'd', 'e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (currentSessionIndex == "c") {
                let end_time = calculateEndTime(currentSessionIndex, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["d"]['sc_h'].toString() + minutePadding(sessionSettings["d"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['d', 'e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (currentSessionIndex == "d") {
                let end_time = calculateEndTime(currentSessionIndex, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["e"]['sc_h'].toString() + minutePadding(sessionSettings["e"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['e', 'f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (currentSessionIndex == "e") {
                let end_time = calculateEndTime(currentSessionIndex, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["f"]['sc_h'].toString() + minutePadding(sessionSettings["f"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['f', 'end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            } else if (currentSessionIndex == "f") {
                let end_time = calculateEndTime(currentSessionIndex, e.target.value).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                let next_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))

                if (current_session_value >= next_session_value) {
                    let session_array = ['end']
                    for (let i in session_array) {
                        if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                            setSessionSettings( prev => ({
                                ...prev,
                                [session_array[i]]: {
                                    ...prev[session_array[i]],
                                    "io_sc": 0
                                }
                            }))
                        }
                    }
                }
            }

            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [currentSessionIndex]: {
                        ...prev[currentBlockIndex][currentSessionIndex],
                        [field]:  parseFloat(e.target.value)
                    }
                }
            }))
        }
    }

    function handleSwitchChange(e, field, key) {

        if (key) {
            if (isNaN(blockSettings[currentBlockIndex][key]["duration"])) return alert("Invalid input")
            let val = 0
            if (blockSettings[currentBlockIndex][key][field] == 0) {
                val = 1
            }
            // Show All session input change
            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [key]: {
                        ...prev[currentBlockIndex][key],
                        [field]:  val
                    }
                }
            }))

            // validate session settings
            if (field == "io_sc") {
                let end_time = calculateEndTimeSwitch(key, val).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))

                // // validate if block values are larger than session end_value
                // let end_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))
                // if (sessionSettings[key]["io_sc"] == "1" && current_session_value >= end_session_value) {
                //     errorToast("Value larger than end session value")
                //     setSessionSettings( prev => ({
                //         ...prev,
                //         [key]: {
                //             ...prev[key],
                //             "io_sc": 0
                //         }
                //     }))
                // }

                if (key == "a") {
                    let next_session_value = parseInt(sessionSettings["b"]['sc_h'].toString() + minutePadding(sessionSettings["b"]['sc_m'].toString()))

                    console.log(current_session_value, next_session_value)

                    if (current_session_value >= next_session_value) {
                        let session_array = ['b', 'c']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (key == "b") {
                    let next_session_value = parseInt(sessionSettings["c"]['sc_h'].toString() + minutePadding(sessionSettings["c"]['sc_m'].toString()))

                    console.log(current_session_value, next_session_value)

                    if (current_session_value >= next_session_value) {
                        let session_array = ['c']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (key == "c") {
                    console.log("hello2")
                    // let end_time = calculateEndTimeSwitch(key, val).split(":")
                    // let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                    // let next_session_value = parseInt(sessionSettings["d"]['sc_h'].toString() + minutePadding(sessionSettings["d"]['sc_m'].toString()))

                    // if (current_session_value >= next_session_value) {
                    //     let session_array = ['d', 'e', 'f', 'end']
                    //     for (let i in session_array) {
                    //         if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                    //             setSessionSettings( prev => ({
                    //                 ...prev,
                    //                 [session_array[i]]: {
                    //                     ...prev[session_array[i]],
                    //                     "io_sc": 0
                    //                 }
                    //             }))
                    //         }
                    //     }
                    // }
                } else if (key == "d") {
                    let next_session_value = parseInt(sessionSettings["e"]['sc_h'].toString() + minutePadding(sessionSettings["e"]['sc_m'].toString()))

                    if (current_session_value >= next_session_value) {
                        let session_array = ['e', 'f', 'end']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (key == "e") {
                    let next_session_value = parseInt(sessionSettings["f"]['sc_h'].toString() + minutePadding(sessionSettings["f"]['sc_m'].toString()))

                    if (current_session_value >= next_session_value) {
                        let session_array = ['f', 'end']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (key == "f") {
                    let next_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))

                    if (current_session_value >= next_session_value) {
                        let session_array = ['end']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                }
            }
            
        } else {
            if (isNaN(blockSettings[currentBlockIndex][currentSessionIndex]["duration"])) return alert("Invalid input")
            let val = 0
            if (blockSettings[currentBlockIndex][currentSessionIndex][field] == 0) {
                val = 1
            }

            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [currentSessionIndex]: {
                        ...prev[currentBlockIndex][currentSessionIndex],
                        [field]: val
                    }
                }
            }))

            // validate session settings
            if (field == "io_sc") {
                let end_time = calculateEndTimeSwitch(currentSessionIndex, val).split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))

                // // validate if block values are larger than session end_value
                // let end_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))
                // if (sessionSettings[currentSessionIndex]["io_sc"] == "1" && current_session_value >= end_session_value) {
                //     errorToast("Value larger than end session value")

                //     setSessionSettings( prev => ({
                //         ...prev,
                //         [currentSessionIndex]: {
                //             ...prev[currentSessionIndex],
                //             "io_sc": 0
                //         }
                //     }))
                // }

                if (currentSessionIndex == "a") {
                    let next_session_value = parseInt(sessionSettings["b"]['sc_h'].toString() + minutePadding(sessionSettings["b"]['sc_m'].toString()))

                    console.log(current_session_value, next_session_value)

                    if (current_session_value >= next_session_value) {
                        let session_array = ['b', 'c']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (currentSessionIndex == "b") {
                    let next_session_value = parseInt(sessionSettings["c"]['sc_h'].toString() + minutePadding(sessionSettings["c"]['sc_m'].toString()))

                    console.log(current_session_value, next_session_value)

                    if (current_session_value >= next_session_value) {
                        let session_array = ['c']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (currentSessionIndex == "c") {
                    // let end_time = calculateEndTimeSwitch(currentSessionIndex, val).split(":")
                    // let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                    // let next_session_value = parseInt(sessionSettings["d"]['sc_h'].toString() + minutePadding(sessionSettings["d"]['sc_m'].toString()))

                    // if (current_session_value >= next_session_value) {
                    //     let session_array = ['d', 'e', 'f', 'end']
                    //     for (let i in session_array) {
                    //         if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                    //             setSessionSettings( prev => ({
                    //                 ...prev,
                    //                 [session_array[i]]: {
                    //                     ...prev[session_array[i]],
                    //                     "io_sc": 0
                    //                 }
                    //             }))
                    //         }
                    //     }
                    // }
                } else if (currentSessionIndex == "d") {
                    let next_session_value = parseInt(sessionSettings["e"]['sc_h'].toString() + minutePadding(sessionSettings["e"]['sc_m'].toString()))

                    if (current_session_value >= next_session_value) {
                        let session_array = ['e', 'f', 'end']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (currentSessionIndex == "e") {
                    let next_session_value = parseInt(sessionSettings["f"]['sc_h'].toString() + minutePadding(sessionSettings["f"]['sc_m'].toString()))

                    if (current_session_value >= next_session_value) {
                        let session_array = ['f', 'end']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                } else if (currentSessionIndex == "f") {
                    let next_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))

                    if (current_session_value >= next_session_value) {
                        let session_array = ['end']
                        for (let i in session_array) {
                            if (sessionSettings[session_array[i]]['io_sc'] == 1) {
                                setSessionSettings( prev => ({
                                    ...prev,
                                    [session_array[i]]: {
                                        ...prev[session_array[i]],
                                        "io_sc": 0
                                    }
                                }))
                            }
                        }
                    }
                }
            }
        }
    }

    function validateNan() {
        for (let ses in blockSettings[currentBlockIndex]) {
            if (ses != "blkdisable") {
                if (isNaN(blockSettings[currentBlockIndex][ses]["duration"])) {
                    return false
                }
            }
        }
        return true
    }

    function handleBlkDisable(value) {
        console.log(value)
        if(!validateNan()) return alert("Invalid Input")
        setBlockSettings(prev=> ({
            ...prev,
            [currentBlockIndex] : {
                ...prev[currentBlockIndex],
                blkdisable : value ? 0 : 1
            }
        }))
    }

    useEffect(() => {
        // a
        let end_time = calculateEndTime("a").split(":")
        let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
        let next_session_value = parseInt(sessionSettings["b"]['sc_h'].toString() + minutePadding(sessionSettings["b"]['sc_m'].toString()))
        if (sessionSettings["a"]['io_sc'] == 1 && current_session_value >= next_session_value && sessionSettings["b"]['io_sc'] == 1) {
            setSessionSettings( prev => ({
                ...prev,
                ["b"]: {
                    ...prev["b"],
                    "io_sc": 0
                },
                ["c"]: {
                    ...prev["c"],
                    "io_sc": 0
                }
            }))
            return false
        }
        
        // b
        end_time = calculateEndTime("b").split(":")
        current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
        next_session_value = parseInt(sessionSettings["c"]['sc_h'].toString() + minutePadding(sessionSettings["c"]['sc_m'].toString()))
        if (sessionSettings["b"]['io_sc'] == 1 && current_session_value >= next_session_value && sessionSettings["c"]['io_sc'] == 1) {
            setSessionSettings( prev => ({
                ...prev,
                ["c"]: {
                    ...prev["c"],
                    "io_sc": 0
                }
            }))
            return false
        }

        // c
        end_time = calculateEndTime("c").split(":")
        current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
        next_session_value = parseInt(sessionSettings["d"]['sc_h'].toString() + minutePadding(sessionSettings["d"]['sc_m'].toString()))
        if (sessionSettings["c"]['io_sc'] == 1 && current_session_value >= next_session_value && sessionSettings["d"]['io_sc'] == 1) {
            setSessionSettings( prev => ({
                ...prev,
                ["d"]: {
                    ...prev["d"],
                    "io_sc": 0
                },
                ["e"]: {
                    ...prev["e"],
                    "io_sc": 0
                },
                ["f"]: {
                    ...prev["f"],
                    "io_sc": 0
                }
            }))
            return false
        }
    }, [blockSettings])
    
    function calculateEndTime(session){
        if (session == "a" ||session == "b" ||session == "c") {
            let duration = 0
            for (let i in blockSettings) {
                if (blockSettings[i]["blkdisable"] == 0 && blockSettings[i][session]["io_sc"] == 1) {
                    duration += parseInt( blockSettings[i][session]["duration"] )
                }
            }
            let hours = parseInt(sessionSettings[session]["sc_h"]) * 60
            duration += hours
            duration += parseInt(sessionSettings[session]["sc_m"])
            return convertMinsToHrsMins(duration)
        }
        return "00:00"
    }

    

    return (
        <>
        <div className='d-flex justify-content-between w-100 m-3'>
            <Button variant="outlined" onClick={()=> {
                if (currentBlockIndex == 1) {
                    setCurrentBlockIndex(5)
                } else {
                    setCurrentBlockIndex(currentBlockIndex - 1)
                }
            }}><ArrowBackIos/></Button>
            <h4>Block {currentBlockIndex}</h4>
            <Button variant="outlined" onClick={()=> {
                if (currentBlockIndex == 5) {
                    setCurrentBlockIndex(1)
                } else {
                    setCurrentBlockIndex(currentBlockIndex + 1)
                }
            }}><ArrowForwardIos/></Button>
        </div>
        <div className='d-flex justify-content-center w-100 mt-3'>
        <h6>Block Setting</h6>
        </div>
        <div className='d-flex justify-content-center w-100 mb-3'>
            <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Disable</p></div>
            <Switch
            checked={blockSettings[currentBlockIndex]["blkdisable"] == 0} 
            onChange={(e)=>{ 
                handleBlkDisable(e.target.checked)
            }}/>
            <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Enable</p></div>
        </div>

        {showAllSession ? (
            Object.keys(blockSettings[currentBlockIndex]).map((key)=> {
                if (key != "blkdisable") {
                    return (
                        <Grid key={key} container spacing={2} style={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            margin: '2%'
                        }}>
                            <div style={{margin: '1% auto'}}>
                                <h4>Session {getSessionNum(key)}</h4>
                                {/* {getSessionNum(key) == 1 || getSessionNum(key) == 2 || getSessionNum(key) == 3 ? "TIME": (
                                (getSessionNum(key) == 4 || getSessionNum(key) == 5 || getSessionNum(key) == 6 ? "RADIATION" : "")
                                )} */}
                            </div>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Disable</p></div>
                                        <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][key]["io_sc"])} onChange={(e)=>{ handleSwitchChange(e, "io_sc", key) }}/>
                                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Enable</p></div>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Water</p></div>
                                        <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][key]["medium"])} onChange={(e)=>{ handleSwitchChange(e, "medium", key) }}/>
                                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Fertilizer</p></div>
                                    </Grid>
                                </Grid>
                            <Grid item xs={12}>
                                <TextField type='number' disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} fullWidth label="Duration (min) 1-60" variant="outlined" value={blockSettings[currentBlockIndex][key]["duration"]} onChange={(e)=>{ handleInputChange(e, "duration", key) }}/>
                            </Grid>
                            {( getSessionNum(key) == 4 || getSessionNum(key) == 5 || getSessionNum(key) == 6 ) && (
                                <Grid item xs={12}>
                                    <TextField type='number' disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} id="outlined-basic" fullWidth label="Radiation Setting (J/cm2) 10-1000" variant="outlined" value={blockSettings[currentBlockIndex][key]["sppar"]} onChange={(e)=>{ handleInputChange(e, "sppar", key) }}/>
                                </Grid> 
                            )}
                        </Grid>
                    )
                }
            })
        ) : (
            <Grid container spacing={2} style={{
                border: '1px solid gray',
                borderRadius: '10px',
                margin: '2%'
            }}>
                <div className='d-flex justify-content-between w-100 m-3'>
                    <Button variant="outlined" onClick={()=> {
                        if (getSessionNum(currentSessionIndex) == 1) {
                            setCurrentSessionIndex("f")
                        } else {
                            let desc = getSessionNum(currentSessionIndex) - 1
                            setCurrentSessionIndex(getSessionLetter(desc))
                        }
                    }}><ArrowBackIos/></Button>
                    <h4>Session {getSessionNum(currentSessionIndex)}</h4>
                    <Button variant="outlined" onClick={()=> {
                        if (getSessionNum(currentSessionIndex) == 6) {
                            setCurrentSessionIndex("a")
                        } else {
                            let inc = getSessionNum(currentSessionIndex) + 1
                            setCurrentSessionIndex(getSessionLetter(inc))
                        }
                    }}><ArrowForwardIos/></Button>
                </div>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Disable</p></div>
                        <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][currentSessionIndex]["io_sc"])} onChange={(e)=>{ handleSwitchChange(e, "io_sc") }}/>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Enable</p></div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Water</p></div>
                        <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][currentSessionIndex]["medium"])} onChange={(e)=>{ handleSwitchChange(e, "medium") }}/>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Fertilizer</p></div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} type='number' fullWidth label="Duration (min) 1-60" variant="outlined" value={blockSettings[currentBlockIndex][currentSessionIndex]["duration"]} onChange={(e)=>{ handleInputChange(e, "duration") }}/>
                </Grid>
                {( getSessionNum(currentSessionIndex) == 4 || getSessionNum(currentSessionIndex) == 5 || getSessionNum(currentSessionIndex) == 6 ) && (
                    <Grid item xs={12}>
                        <TextField disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} type='number' id="outlined-basic" fullWidth label="Radiation Setting (J/cm2) 10-1000" variant="outlined" value={blockSettings[currentBlockIndex][currentSessionIndex]["sppar"]} onChange={(e)=>{ handleInputChange(e, "sppar") }}/>
                    </Grid>
                )}
            </Grid>
        )}


        <Grid item xs={12}>
            <Button color='info' fullWidth variant="outlined" onClick={()=>{setShowAllSession(!showAllSession)}}>
                {showAllSession ? 'Hide ' : 'Show '} all session</Button>
        </Grid>
        {showSessionForm && (
        <Grid item xs={12}>
            <Button color='warning' fullWidth variant="contained" onClick={()=>{
                if(!validateNan()) return alert("Invalid Input")
                uploadController()
            }}>Upload to Controller</Button>
        </Grid>
        )}
        </>
    )
}

const minutePadding =(min)=>{
    if (min.toString().length < 2) {
        return '0' + min.toString()
    }
    return min.toString()
}

const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
}


const getCheckedBoolValue =(val)=>{
    if (val == 1) {
        return true
    } else {
        return false
    }
}
const getSessionNum = (letter) => {
    switch (letter) {
        case 'a':
            return 1
            break;
        case 'b':
            return 2
            break;
        case 'c':
            return 3
            break;
        case 'd':
            return 4
            break;
        case 'e':
            return 5
            break;
        case 'f':
            return 6
            break;
    }
}
const getSessionLetter = (num) => {
    switch (num) {
        case 1:
            return 'a'
            break;
        case 2:
            return 'b'
            break;
        case 3:
            return 'c'
            break;
        case 4:
            return 'd'
            break;
        case 5:
            return 'e'
            break;
        case 6:
            return 'f'
            break;
    }
}

export default AR00XX3TBBlockSettingDb