import React, { useEffect, useState } from "react";
import AddCategory from "../../Components/Card/add-category";
import CardCategoryV3 from "../../Components/Card/card-category/indexV3";
import AddCategoryModal from "../../Components/Node/AddCategoryModal";
import ScanQr from "../../Containers/ScanQr/index";
import auth from "../../services/auth";
import retrieveNode from "../../services/node/retrieve-node";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import CardSingleNodeV3 from "../../Components/Card/single-node/indexV3";

function DashboardV3() {
  const [nodeWithCategory, setNodeWithCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [nodeUncategory, setNodeUncategory] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [categoryV2List, setCategoryV2List] = useState([])
  const [individualV2List, setIndividualV2List] = useState([])

  useEffect(async () => {
    setLoading(true);
    const resp = await retrieveNode.getInstance().getNodeV2()
    setCategoryV2List(resp.categories)
    setIndividualV2List(resp.individuals)
    setLoading(false);
  }, []);

  function closeAddCategory() {
    setShowAddCategory(false);
  }

  const [addNodeModal, setAddNodeModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const addNewNode = () => {
    setAddNodeModal(true);
    if (
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        navigator.userAgent.toLowerCase()
      )
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const [addCategoryModal, setAddCategoryModal] = useState(false)

  return (
    <>
      {loading && <LoadingSpinner />}
      {showAddCategory && <AddCategory closeAddCategory={closeAddCategory} />}
      <div className="container">
        <div className="row m-3 d-flex justify-content-end p-2">
            <button className="btn btn-primary" onClick={()=>{ setAddCategoryModal(true) }}>Add Category</button>
        </div>
        <div className="row">
          {categoryV2List.map((category, index) => (
            <div className="col-12 col-md-3 mb-3" key={category.name}>
              <CardCategoryV3
                ms={index}
                categoryName={category.name}
                node={category.node}
              />
            </div>
          ))}
        </div>
        <hr />
        <div className="row pb-4">
          {individualV2List.map((node, index) => (
            <div className="col-12 col-md-3" key={node.device_id}>
              <CardSingleNodeV3 node={node} lcdId={"single_led_" + index} />
            </div>
          ))}
          <div className="col-12 col-md-3 mb-3">
            <div className="card card-container h-100 mx-auto">
              <div className="card-header">New Node</div>
              <div className="card-body">
                <button className="btn btn-info" onClick={addNewNode} disabled={localStorage.getItem("cloudatik_demo")}>
                  Add New Node
                </button>
              </div>
            </div>
          </div>

          <ScanQr
            setAddNodeModal={setAddNodeModal}
            setIsMobile={setIsMobile}
            isMobile={isMobile}
            addNodeModal={addNodeModal}
          />
        </div>
      </div>

      <AddCategoryModal 
      addCategoryModal={addCategoryModal}
      setAddCategoryModal={setAddCategoryModal}
      setLoading={setLoading}
      />
    </>
  );
}

export default DashboardV3
