import React, {useState,useEffect} from 'react'
import { Button, TextField, Dialog, DialogContent, FormControlLabel, Switch, FormGroup, Card, CardContent, Grid, CardHeader } from '@mui/material/';
import { errorToast, successToast } from '../../shared/notification';
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner';
import { Refresh } from '@material-ui/icons';
import { Link, useParams } from "react-router-dom";

var mqtt = require('mqtt');

const MQTT_USERNAME = process.env.REACT_APP_MQTT_USERNAME
const MQTT_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD
const MQTT_SERVER = process.env.REACT_APP_MQTT_SERVER
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT

const ManualOperation = () => {
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [manualSettings, setManualSettings] = useState(manualDefaultData)
    const [firstSectionStatus, setFirstSectionStatus] = useState(true)
    const [secondSectionStatus, setSecondSectionStatus] = useState(true)
    const [thirdSectionStatus, setThirdSectionStatus] = useState(true)
    const [submitStatus, setSubmitStatus] = useState(true)
    const [progressPercentage, setProgressPercentage] = useState(0)
    const [progressLabel, setProgressLabel] = useState('Sending Block Valve...')
    const [toggleProgressModal, setToggleProgressModal] = useState(false)
    const [timeoutButtonStatus, setTimeoutButtonStatus] = useState(false)
    let { device, device_id } = useParams()
    // let device = 'ar00xx'
    // let device_id = 'LWZA0i'

    const submit = () => {
        if (parseInt(manualSettings.timeout) == 0) return errorToast("Water Pump Timeout cannot be 0")

        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })

        // let payload = ''
        let aswrPayload_1 = `{"messageId": "AR1","devices": [{ "deviceId": "manual", "deviceData": { "mode": 1, "valve1": ${manualSettings.valve1}, "valve2": ${manualSettings.valve2}, "valve3": ${manualSettings.valve3}, "valve4": ${manualSettings.valve4},"valve5": ${manualSettings.valve5} }}]}`
        let aswrPayload_2 = `{"messageId": "AR2","devices": [{ "deviceId": "manual", "deviceData": { "valvew": ${manualSettings.valvew}, "valvef": ${manualSettings.valvef} }}]}`
        let aswrPayload_3 = `{"messageId": "AR3","devices": [{ "deviceId": "manual", "deviceData": { "pumpw": ${manualSettings.pumpw} }}]}`

        // if (step == 1) {
        //     payload = aswrPayload_1
        // } else if (step == 2) {
        //     payload = aswrPayload_2
        // } else if (step == 3) {
        //     payload = aswrPayload_3
        // }
        // console.log(payload)

        // setLoading(true)
        setToggleProgressModal(true)
        client.on('connect', function () {
            console.log(`*** subscribing to farm/aswa/${device_id}`);
            client.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}`);
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_1);
                    client.publish('farm/aswr/' + device_id, aswrPayload_1);
                }
            });
        });

        client.on('message', function (topic, message) {
            console.log("*** received from topic: ", topic);
            console.log("*** received message", message.toString())
            // message is Buffer
            // client.end();
            
            const message_json = JSON.parse(message.toString())
            
            if (message_json.messageId == 'AR1') {
                setProgressPercentage(30)
                setProgressLabel("Block Valve opened successfully")
                setTimeout(() => {
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_2);
                    client.publish('farm/aswr/' + device_id, aswrPayload_2);
                }, 1000)
            } else if (message_json.messageId == 'AR2') {
                setProgressPercentage(70)
                setProgressLabel("Fertilizer/Water Valve opened successfully")
                setTimeout(() => {
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_3);
                    client.publish('farm/aswr/' + device_id, aswrPayload_3);
                }, 1800)
            } else if (message_json.messageId == 'AR3') {
                setProgressPercentage(100)
                setProgressLabel("Pump triggered successfully")
                // setLoading(false)
                setToggleProgressModal(false)
                if (message_json.result && message_json.result === "error") {
                    errorToast(`Please resubmit.`);
                } else {
                    successToast("Value updated.");
                    setTimeout(()=>{
                        window.location.reload()
                    },2000)
                }
            }
        });
    }

    useEffect(() => {
        setStep(1)
        loadFromController()
    }, [])
    

    const loadFromController=()=>{
        // wss://servertwo.cloudatik.com:9001
        // wss://imre.cloudatik.com:8192
        let srrdPayload = `{ "messageId":"${device_id}","devices":"manual", "mode":2 }`

        console.log(srrdPayload)
    
        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })
        client.on('connect', function () {
            console.log("*** connected.");
            console.log(`*** subscribing to farm/srrp/${device_id}...`);
            client.subscribe('farm/srrp/' + device_id, function (err) {
                setLoading(true)
                if (!err) {
                    console.log(`*** subscribed to farm/srrp/${device_id}.`);
                    console.log(`*** publishing to farm/srrd/${device_id}...`);
                    client.publish('farm/srrd/' + device_id, srrdPayload);
                }
            });
        });

        client.on('message', function (topic, message) {
            setLoading(false)
            const responseMessage = JSON.parse(message);
            console.log(`*** message received from topic: ${topic}.`);
            console.log(`*** responseMessage:`, responseMessage.devices);
            client.end();
            responseMessage.devices[0].deviceData['timeout'] = minutePadding(responseMessage.devices[0].deviceData['timeout'])

            setManualSettings(responseMessage.devices[0].deviceData)

            if (responseMessage.devices[0].deviceData.mode == 1) {
                setFirstSectionStatus(false)

                if (
                    responseMessage.devices[0].deviceData['valve1'] == 1 ||
                    responseMessage.devices[0].deviceData['valve2'] == 1 ||
                    responseMessage.devices[0].deviceData['valve3'] == 1 ||
                    responseMessage.devices[0].deviceData['valve4'] == 1 ||
                    responseMessage.devices[0].deviceData['valve5'] == 1
                ) {
                    setSecondSectionStatus(false)
                }

                if (
                    responseMessage.devices[0].deviceData['valvef'] == 1 ||
                    responseMessage.devices[0].deviceData['valvew'] == 1
                ) {
                    setThirdSectionStatus(false)
                    setSubmitStatus(false)
                }

            } else {
                setFirstSectionStatus(true)
                setSecondSectionStatus(true)
                setThirdSectionStatus(true)
                setTimeoutButtonStatus(true)
            }
            
            const message_json = JSON.parse(message.toString());
            if (message_json.result && message_json.result === "error") {
                errorToast(`Something went wrong. Please contact administrator.`);
            } else {
                successToast("Successfully Loaded from controller.");
            }
        })
    }


    const handleChange =(e, field)=> {
        let switchStatus = e.target.checked ? 1 : 0
        if (field == 'valve1') {
            if (firstSectionStatus) return null
            if (switchStatus == 0 && manualSettings.valve2 == 0 && manualSettings.valve3 == 0 && manualSettings.valve4 == 0 && manualSettings.valve5 == 0) {
                // check if valve1-5 is 0. If all zero then setSecondSectionStatus(true)
                setSecondSectionStatus(true)
                setThirdSectionStatus(true)
                setManualSettings(prev=> ({
                    ...prev,
                    "valvef" : 0,
                    "valvew" : 0,
                    "pumpw" : 0,
                }))
            } else {
                setSecondSectionStatus(false)
            }
            setManualSettings(prev=> ({
                ...prev,
                "valve1" : switchStatus,
                "valve2" : 0,
                "valve3" : 0,
                "valve4" : 0,
                "valve5" : 0,
            }))
        } else if (field == 'valve2') {
            if (firstSectionStatus) return null
            if (manualSettings.valve1 == 0 && switchStatus == 0 && manualSettings.valve3 == 0 && manualSettings.valve4 == 0 && manualSettings.valve5 == 0) {
                // check if valve1-5 is 0. If all zero then setSecondSectionStatus(true)
                setSecondSectionStatus(true)
                setThirdSectionStatus(true)
                setManualSettings(prev=> ({
                    ...prev,
                    "valvef" : 0,
                    "valvew" : 0,
                    "pumpw" : 0,
                }))
            } else {
                setSecondSectionStatus(false)
            }
            setManualSettings(prev=> ({
                ...prev,
                "valve1" : 0,
                "valve2" : switchStatus,
                "valve3" : 0,
                "valve4" : 0,
                "valve5" : 0,
            }))
        } else if (field == 'valve3') {
            if (firstSectionStatus) return null
            if (manualSettings.valve1 == 0 && manualSettings.valve2 == 0 && switchStatus == 0 && manualSettings.valve4 == 0 && manualSettings.valve5 == 0) {
                // check if valve1-5 is 0. If all zero then setSecondSectionStatus(true)
                setSecondSectionStatus(true)
                setThirdSectionStatus(true)
                setManualSettings(prev=> ({
                    ...prev,
                    "valvef" : 0,
                    "valvew" : 0,
                    "pumpw" : 0,
                }))
            } else {
                setSecondSectionStatus(false)
            }
            setManualSettings(prev=> ({
                ...prev,
                "valve1" : 0,
                "valve2" : 0,
                "valve3" : switchStatus,
                "valve4" : 0,
                "valve5" : 0,
            }))
        } else if (field == 'valve4') {
            if (firstSectionStatus) return null
            if (manualSettings.valve1 == 0 && manualSettings.valve2 == 0 && manualSettings.valve3 == 0 && switchStatus == 0 && manualSettings.valve5 == 0) {
                // check if valve1-5 is 0. If all zero then setSecondSectionStatus(true)
                setSecondSectionStatus(true)
                setThirdSectionStatus(true)
                setManualSettings(prev=> ({
                    ...prev,
                    "valvef" : 0,
                    "valvew" : 0,
                    "pumpw" : 0,
                }))
            } else {
                setSecondSectionStatus(false)
            }
            setManualSettings(prev=> ({
                ...prev,
                "valve1" : 0,
                "valve2" : 0,
                "valve3" : 0,
                "valve4" : switchStatus,
                "valve5" : 0,
            }))
        } else if (field == 'valve5') {
            if (firstSectionStatus) return null 
            if (manualSettings.valve1 == 0 && manualSettings.valve2 == 0 && manualSettings.valve3 == 0 && manualSettings.valve4 == 0 && switchStatus == 0) {
                // check if valve1-5 is 0. If all zero then setSecondSectionStatus(true)
                setSecondSectionStatus(true)
                setThirdSectionStatus(true)
                setManualSettings(prev=> ({
                    ...prev,
                    "valvef" : 0,
                    "valvew" : 0,
                    "pumpw" : 0,
                }))
            } else {
                setSecondSectionStatus(false)
            }
            setManualSettings(prev=> ({
                ...prev,
                "valve1" : 0,
                "valve2" : 0,
                "valve3" : 0,
                "valve4" : 0,
                "valve5" : switchStatus,
            }))
        } 

        if (field == 'valvef') {
            if (secondSectionStatus) return null 
            let val = 0
            if (manualSettings['valvef'] == 0) {
                val = 1
            }
            setManualSettings(prev=> ({
                ...prev,
                [field] : val,
            }))

            if (manualSettings.valvew == 0 && val == 0) {
                setThirdSectionStatus(true)
                setManualSettings(prev=> ({
                    ...prev,
                    "pumpw" : 0,
                }))
            } else {
                setThirdSectionStatus(false)
                setSubmitStatus(false)
            }

        } else if (field == 'valvew') {
            if (secondSectionStatus) return null 
            let val = 0
            if (manualSettings['valvew'] == 0) {
                val = 1
            }
            setManualSettings(prev=> ({
                ...prev,
                [field] : val,
            }))

            if (val == 0 && manualSettings.valvef == 0) {
                setThirdSectionStatus(true)
                setManualSettings(prev=> ({
                    ...prev,
                    "pumpw" : 0,
                }))
            } else {
                setThirdSectionStatus(false)
                setSubmitStatus(false)
            }

        }

        if (field == 'pumpw') {
            if (thirdSectionStatus) return null 
             let val = 0
            if (manualSettings['pumpw'] == 0) {
                val = 1
            }
            setManualSettings(prev=> ({
                ...prev,
                "pumpw" : val,
            }))
            setSubmitStatus(false)
        }
    }
    

    const getCheckedBoolValue =(val)=>{
        if (val == 1) {
            return true
        } else {
            return false
        }
    }

    const onClickAutoManual=()=>{
        let val = 0
        if (manualSettings['mode'] == 0) {
            val = 1
        }

        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })

        let payload = `{"messageId": "AR1","devices": [{ "deviceId": "manual", "deviceData": { "mode": ${val} }}]}`
        setLoading(true)
        client.on('connect', function () {
            console.log(`*** subscribing to farm/aswa/${device_id}`);
            client.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}`);
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, payload);
                    client.publish('farm/aswr/' + device_id, payload);
                }
            });
        });

        client.on('message', function (topic, message) {
            setLoading(false)
            console.log("*** received from topic: ", topic);
            console.log("*** received message", message.toString());
            // message is Buffer
            client.end();

            const message_json = JSON.parse(message.toString());
            
            if (message_json.result && message_json.result === "error") {
                errorToast(`Please resubmit.`);
            } else {
                successToast("Updated mode");
                setTimeout(()=>{
                    window.location.reload()
                }, 1200)
            }

        });
    }

    const minutePadding =(min)=>{
        if (min.toString().length < 2) {
            return '0' + min.toString()
        }
        return min.toString()
    }

    const handleInputChange=(e, field)=>{
        if (e.target.value > 59 || e.target.value < 0) {
            return errorToast('Invalid minutes input.')
        }
        setManualSettings(prev => ({
            ...prev,
            [field]: e.target.value
        }))
    }

    function submitTimout() {
        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })

        let payload = `{"messageId": "timeout","devices": [{ "deviceId": "manual", "deviceData": { "timeout": ${manualSettings.timeout} }}]}`
        setLoading(true)
        client.on('connect', function () {
            console.log(`*** subscribing to farm/aswa/${device_id}`);
            client.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}`);
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, payload);
                    client.publish('farm/aswr/' + device_id, payload);
                }
            });
        });

        client.on('message', function (topic, message) {
            setLoading(false)
            console.log("*** received from topic: ", topic);
            console.log("*** received message", message.toString());
            // message is Buffer
            client.end();

            const message_json = JSON.parse(message.toString());
            
            if (message_json.result && message_json.result === "error") {
                errorToast(`Please resubmit.`)
            } else {
                successToast("Updated timeout")
            }

        });

    }

    return (
        <>
        {loading && <LoadingSpinner/>}
        
        <div style={{display:'flex', justifyContent: 'space-evenly', marginBottom: '1.5rem'}}>
            <Link to={`/remote-agrirrig-rad/block/${device}/${device_id}`}>
                <Button variant="contained">Block {'&'} Session Setting</Button>
            </Link>
            <Link to={`/remote-agrirrig-rad/manual/${device}/${device_id}`}>
                <Button variant="contained" color="secondary">Manual Setting</Button>
            </Link>
        </div>
        <Card>
            <div style={{padding: '0 1.5rem', display: 'flex', justifyContent: 'space-between'}}>
                <h3>Manual Operations</h3>
                <Button variant="outlined" onClick={()=> {
                    onClickAutoManual()
                }}>{manualSettings.mode == 1 ? 'MANUAL' : 'AUTO'}</Button>
                <Button variant="outlined" onClick={()=> {
                    return window.location.reload()
                }}><Refresh/></Button>
            </div>
            <CardContent>
            <Grid container>
                <Grid container xs={12} marginX="10" style={{padding: '0 10px'}}>
                    <div className="row" style={{borderRadius: '10px', marginBottom:'1.5rem', width: "100%", padding: 0}}>
                        <div className="col-10">
                            <TextField label="Water Pump Timeout (minutes)" disabled={firstSectionStatus} type='number' variant="outlined" size="small" value={manualSettings.timeout} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'timeout')}}/>
                        </div>
                        <div className="col-2">
                            <Button color='success' disabled={timeoutButtonStatus} fullWidth variant="contained" onClick={()=>{submitTimout()}}>Submit Timeout</Button>
                        </div>
                    </div>
                    <div  style={{border: '1px solid grey', borderRadius: '10px', width: '100%', marginBottom:'1rem'}}>
                        <Grid item xs={12} >
                            <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.valve1)}
                                checked={getCheckedBoolValue(manualSettings.valve1)}
                                onClick={(e)=>{handleChange(e, 'valve1')}}
                                disabled={firstSectionStatus}
                                control={<Switch color="primary" disabled={firstSectionStatus}/>}
                                label="Block 1 Valve"
                                labelPlacement="start"
                                />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.valve2)}
                                checked={getCheckedBoolValue(manualSettings.valve2)}
                                onClick={(e)=>{handleChange(e, 'valve2')}}
                                disabled={firstSectionStatus}
                                control={<Switch color="primary" disabled={firstSectionStatus}/>}
                                label="Block 2 Valve"
                                labelPlacement="start"
                                />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.valve3)}
                                checked={getCheckedBoolValue(manualSettings.valve3)}
                                onClick={(e)=>{handleChange(e, 'valve3')}}
                                disabled={firstSectionStatus}
                                control={<Switch color="primary" disabled={firstSectionStatus}/>}
                                label="Block 3 Valve"
                                labelPlacement="start"
                                />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.valve4)}
                                checked={getCheckedBoolValue(manualSettings.valve4)}
                                onClick={(e)=>{handleChange(e, 'valve4')}}
                                disabled={firstSectionStatus}
                                control={<Switch color="primary" disabled={firstSectionStatus}/>}
                                label="Block 4 Valve"
                                labelPlacement="start"
                                />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.valve5)}
                                checked={getCheckedBoolValue(manualSettings.valve5)}
                                onClick={(e)=>{handleChange(e, 'valve5')}}
                                disabled={firstSectionStatus}
                                control={<Switch color="primary" disabled={firstSectionStatus}/>}
                                label="Block 5 Valve"
                                labelPlacement="start"
                                />
                        </Grid>
                    </div>

                    <Grid item xs={12}>
                        <div style={{border: '1px solid grey', borderRadius: '10px', marginBottom:'1rem'}}>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.valvef)}
                                checked={getCheckedBoolValue(manualSettings.valvef)}
                                onClick={(e)=>{handleChange(e, 'valvef')}}
                                disabled={secondSectionStatus}
                                control={<Switch color="primary" disabled={secondSectionStatus}/>}
                                label="F Valve"
                                labelPlacement="start"
                                />
                                <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.valvew)}
                                checked={getCheckedBoolValue(manualSettings.valvew)}
                                onClick={(e)=>{handleChange(e, 'valvew')}}
                                disabled={secondSectionStatus}
                                control={<Switch color="primary" disabled={secondSectionStatus}/>}
                                label="W Valve"
                                labelPlacement="start"
                                />
                            </FormGroup>
                        </div>
                    </Grid>

               
                    <Grid item xs={12}>
                        <div style={{border: '1px solid grey', borderRadius: '10px', marginBottom:'1rem'}}>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                value={getCheckedBoolValue(manualSettings.pumpw)}
                                checked={getCheckedBoolValue(manualSettings.pumpw)}
                                onClick={(e)=>{handleChange(e, 'pumpw')}}
                                control={<Switch color="primary" disabled={thirdSectionStatus}/>}
                                disabled={thirdSectionStatus}
                                label="Water Pump"
                                labelPlacement="start"
                                />
                            </FormGroup>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Button color='success' disabled={submitStatus} fullWidth variant="contained" size="medium" onClick={()=>{submit()}}>Submit</Button>
                    </Grid>


                    {/* <Grid item xs={12}  style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <Button color='primary' fullWidth variant="contained" size="medium">Unlock</Button>
                        <Button color='primary' fullWidth variant="outlined" size="medium">Auto Mode</Button>
                    </Grid> */}
                
                </Grid>

            </Grid>
            </CardContent>
        </Card>

        <Dialog open={toggleProgressModal} maxWidth="md" fullWidth={true}>
            <DialogContent>
            <div className="progress">
                <div className="progress-bar progress-bar-success" role="progressbar" ariaValuenow={progressPercentage} ariaValuemin={progressPercentage} ariaValuemax="100" style={{width: `${progressPercentage}%`, backgroundColor:'#4ab56d'}}>
                    {progressLabel}
                </div>
            </div>
            </DialogContent>
        </Dialog>
        </>
    )
}

const manualDefaultData = {
    mode: 0,
    pumpw: 0,
    timeout: 1,
    valve1: 0,
    valve2: 0,
    valve3: 0,
    valve4: 0,
    valve5: 0,
    valvef: 0,
    valvew: 0,
}

export default ManualOperation