import React, { useEffect, useState } from "react";
import Nouislider from "nouislider-react";
import wNumb from "wnumb";
import "nouislider/distribute/nouislider.css";

function PropSlider({
    index,
    alarm_enabled,
    plot_limit,
    alarm_min,
    alarm_max,
    slider_min,
    slider_max,
    slide_mix,
    slide_max,
    parameter,
    label,
    limit_high,
    updateValue,
    limit_low,
    multi,
    device_id,
    sensor_set,
    slider_step
}) {

    const [alarmIsChecked, setAlarmIsChecked] = useState(alarm_enabled);
    const [plotIsChecked, setPlotIsChecked] = useState(plot_limit == 1 ? true : false);
    const [limitLow, setLimitLow] = useState(limit_low);
    const [limitHigh, setLimitHigh] = useState(limit_high);
    const [rangeMin, setRangeMin] = useState(alarm_min == null ? parseFloat(slider_min) : parseFloat(alarm_min));
    const [rangeMax, setRangeMax] = useState(alarm_max == null ? parseFloat(slider_max) : parseFloat(alarm_max));

    function onSliderSlide(values, handle) {
        setLimitLow(values[0]);
        setLimitHigh(values[1]);
    }

    useEffect(() => {
        let d = {};
        d["limit_low"] = limitLow;
        d["limit_high"] = limitHigh;
        d["parameter"] = parameter;
        d["enabled"] = alarmIsChecked ? 1 : 0;
        d["category"] = "slider";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = rangeMin;
        d["alarm_max"] = rangeMax;
        d["plot_limit"] = plotIsChecked ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);

    }, [limitLow, limitHigh, plotIsChecked, alarmIsChecked, rangeMin, rangeMax]);

    return (
        <div>
            <form>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">{label}</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"alarmSwitch" + index}
                                        checked={alarmIsChecked}
                                        onChange={() => {
                                            setAlarmIsChecked(!alarmIsChecked);
                                        }}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={"alarmSwitch" + index}
                                    >
                                        Alarm
                                    </label>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"plotSwitch" + index}
                                        checked={plotIsChecked}
                                        onChange={() => setPlotIsChecked(!plotIsChecked)}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={"plotSwitch" + index}
                                    >
                                        Plot Alarm
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <br />
                                <Nouislider range={{
                                    min: parseFloat(rangeMin),
                                    max: parseFloat(rangeMax)
                                }} start={[parseFloat(limitLow), parseFloat(limitHigh)]} connect={true}
                                    tooltips={true} step={parseInt(slider_step)} pips={{
                                        mode: "steps",
                                        density: 4,
                                        format: wNumb({
                                            decimals: 2
                                        })
                                    }} onChange={onSliderSlide} />
                                <br />
                            </div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-4">
                                <br />
                                <br />
                                <input
                                    placeholder="MIN"
                                    id={"inputMin" + index}
                                    style={{ width: "10vw" }}
                                    value={rangeMin}
                                    type={"number"}
                                    onChange={(e) => setRangeMin(parseFloat(e.target.value))}
                                />
                                <br />
                            </div>
                            <div className="col-4">
                                <br />
                                <br />
                                <div>
                                    <input
                                        placeholder="MAX"
                                        style={{ width: "10vw" }}
                                        id={"inputMax" + index}
                                        value={rangeMax}
                                        type={"number"}
                                        onChange={(e) => setRangeMax(parseFloat(e.target.value))}
                                    />
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default PropSlider;
