import React, { useState, useEffect } from "react";

function Switch({
    alarm_enabled,
    plot_limit,
    limit_high,
    parameter,
    index,
    label,
    updateValue,
    multi,
    device_id,
    sensor_set
}) {
    const [alarmState, setAlarmState] = useState(alarm_enabled);
    const [plotState, setPlotState] = useState(plot_limit == 1 ? true : false);

    const [btnText, setBtnText] = useState(
        parseInt(limit_high) == 1 ? "Raining" : "Not Raining"
    );

    function handleButton(e) {
        setBtnText(btnText == "Raining" ? "Not Raining" : "Raining");

        let d = {};
        d["limit_low"] = 0;
        d["limit_high"] = e.target.innerHTML == "Raining" ? 0 : 1;
        d["parameter"] = parameter;
        d["enabled"] =
            document.getElementById("alarmSwitch" + index).checked == true ? 1 : 0;
        d["category"] = "switch";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = "Not Raining";
        d["alarm_max"] = "Raining";
        d["plot_limit"] =
            document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);
    }

    function plotChange() {
        setPlotState(!plotState);

        let d = {};
        d["limit_low"] = 0;
        d["limit_high"] = btnText == "Raining" ? 1 : 0;
        d["parameter"] = parameter;
        d["enabled"] =
            document.getElementById("alarmSwitch" + index).checked == true ? 1 : 0;
        d["category"] = "switch";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = "Not Raining";
        d["alarm_max"] = "Raining";
        d["plot_limit"] =
            document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);
    }

    function alarmChange() {
        setAlarmState(!alarmState);

        let d = {};
        d["limit_low"] = 0;
        d["limit_high"] = btnText == "Raining" ? 1 : 0;
        d["parameter"] = parameter;
        d["enabled"] =
            document.getElementById("alarmSwitch" + index).checked == true ? 1 : 0;
        d["category"] = "switch";
        d["device_id"] = device_id;
        d["username"] = localStorage.getItem("cloudatik");
        d["alarm_min"] = "Not Raining";
        d["alarm_max"] = "Raining";
        d["plot_limit"] =
            document.getElementById("plotSwitch" + index).checked == true ? 1 : 0;
        d["label"] = label;
        d["sensor_set"] = sensor_set;
        updateValue(d, multi);
    }

    return (
        <div>
            <form>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">{label}</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"alarmSwitch" + index}
                                        onChange={alarmChange}
                                        checked={alarmState}
                                    />
                                    <label
                                        className="custom-control-label"
                                        for={"alarmSwitch" + index}
                                    >
                                        Alarm
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"plotSwitch" + index}
                                        onChange={plotChange}
                                        checked={plotState}
                                    />
                                    <label
                                        className="custom-control-label"
                                        for={"plotSwitch" + index}
                                    >
                                        Plot Limit
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block"
                                    onClick={handleButton}
                                >
                                    {btnText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Switch;
