import React, { useState, useEffect } from 'react';
import Controller from '../../Containers/Controller';
import RemoteFertigation from '../../Containers/RemoteFertigation/Dashboard';
import { useParams } from "react-router-dom";

import { Grid, Tabs, Tab, AppBar, Paper, Button } from '@material-ui/core';
import RemoteLed from '../../Containers/Remote/RemoteLed';
import TransplantDate from '../../Containers/TransplantDate';
import ControllerPoll from '../../Containers/ControllerPoll';

const Remote = () => {
    const [type, setType] = useState("");
    let { device, device_id, page, telemetry, serial } = useParams();
    const deviceSeries = device.substring(0, 2).toLowerCase();
    const [controllerMqtt, setControllerMqtt] = useState(false)


    useEffect(() => {
        if (page == "transplant-date") {
            setType("Transplant_Date")
        } else if (page == "controller") {
            setType('Controller')
        } else if (page == "controller-poll") {
            setType('Controller-Poll')
        } else {
            setType("")
        }
    }, [page])

    useEffect(() => {
        if (device.includes("_mqtt")) setControllerMqtt(true)
    }, [device])
    
    let remoteDashboard = <>
       <Grid container style={{ marginBottom: 20, display: 'flex', maxWidth:'750px'}}>
            <Grid item style={{ flex: 1, textAlign: 'center', height: '50px', width: '100%', fontSize: 15, fontWeight: 'bold' }}>
                {controllerMqtt ? (
                    <Button onClick={() => {
                        return window.location.href = `#/remote/${device}/${serial}/${device_id}/${telemetry}/controller`
                     }} variant="contained" color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }}>Controllers</Button>
                ) : (
                    <Button onClick={() => {
                        return window.location.href = `#/remote/${device}/${serial}/${device_id}/${telemetry}/controller-poll`
                     }} variant="contained" color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }}>Controller</Button>
                )}
            </Grid>
            {/* <Grid item style={{ flex: 1, textAlign: 'center', height: '50px', width: '100%', marginLeft: '20px', fontSize: 15, fontWeight: 'bold' }}>
                <Button onClick={() => { setType('Schedueler'); }} variant="contained" color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }}>Schedueler</Button>
            </Grid> */}
            <Grid item style={{ flex: 1, textAlign: 'center', height: '50px', width: '100%', marginLeft: '20px', fontSize: 15, fontWeight: 'bold' }}>
                <Button onClick={() => {
                    return window.location.href = `#/remote/${device}/${serial}/${device_id}/${telemetry}/transplant-date`
                }} variant="contained" color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }}>Transplant Date</Button>
            </Grid>
        </Grid>
        {type === 'Controller' && (<Controller device_id={device_id} />)}
        {/* {type === 'Schedueler' && (<Schedueler device_id={device_id} />)} */}
        {type === 'Transplant_Date' && (<TransplantDate device_id={device_id} />)}
        {type === 'Controller-Poll' && (<ControllerPoll device_id={device_id} />)}
    </>;


    if (deviceSeries === 'af') {
        remoteDashboard = <RemoteFertigation />;
    } else if (device == 'led') {
        remoteDashboard = <RemoteLed />;
    }

    return (
        <div style={{textAlign:'-webkit-center'}}>
            {remoteDashboard}
        </div>
    );

};

export { Remote as RemoteMainLayout };
