import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import OpacityIcon from '@material-ui/icons/Opacity';
import ColorizeIcon from '@material-ui/icons/Colorize';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 8,
    margin: theme.spacing(1),
  },
  button: {
    margin: '0 12px'
  },
  heading: {
    fontSize: '1.4rem'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 2
  },
  label: {
    marginBottom: 0
  },
  formControlLabel: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gridItem: {
    padding: '8px'
  },
  divider: {
    width: '100%',
    '&:last-of-type': {
      display: 'none'
    }
  }
}));

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export default function DaySetting({
  daySettings,
  medium,
  prefix,
  handleInputChange
}) {
  const classes = useStyles();

  const handleSwitch = (event, day) => {
    event.target.value = event.target.checked ? 1 : 0;
    handleInputChange(event, `${prefix}_${day}`);
  };

  return (
    <Box
      border={`1px solid ${grey[300]}`}
      borderRadius={4}
      padding={1}
      marginBottom={2}
    >
      <Box display='flex' alignItems='center' justifyContent='center'>
        {medium === 'WATER'
          ?
          <OpacityIcon />
          :
          <ColorizeIcon />
        }
        <h4 className={classes.heading}>{medium}</h4>
      </Box>
      <Grid container>
        {days.map((day) => (
          <>
            <Grid item xs={12} classes={{ root: classes.gridItem }}>
              <FormControlLabel
                key={day}
                control={
                  <>
                    <Typography variant="h6">{day.toUpperCase()}</Typography>
                    <Switch
                      checked={daySettings[`${prefix}_${day}`] === 1 ? true : false}
                      onChange={(event) => handleSwitch(event, day)}
                      name={`${prefix}_${day}_switch`}
                      color="primary"
                    />
                  </>
                }
                classes={{ root: classes.formControlLabel }}
              />
            </Grid>
            <Divider classes={{ root: classes.divider }} />
          </>
        ))}
      </Grid>
    </Box >
  );
}
